import { useEffect } from "react";

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: (event?: MouseEvent) => void,
  ignoredSelectors: string[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement | null;

      if (ref.current && ref.current.contains(targetElement)) {
        return;
      }

      if (
        ignoredSelectors.some((selector) => targetElement?.closest(selector))
      ) {
        return;
      }

      callback(event);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, callback, ignoredSelectors]);
};

export default useClickOutside;
