import React, { useMemo, useRef } from "react";
import { flexRender, Header, Table } from "@tanstack/react-table";
import clsx from "clsx";
import useClickOutside from "../../../hooks/useClickOutside";
import FilterIcon from "../../icons/FilterIcon";
import FilterMenu from "../FilterMenu/FilterMenu";

interface FilterHeaderProps<T> {
  table: Table<T>;
  header: Header<T, unknown>;
  openFilterColumn: string | null;
  setOpenFilterColumn: (columnId: string | null) => void;
}

const FilterHeader = <T,>({
  table,
  header,
  setOpenFilterColumn,
  openFilterColumn,
}: FilterHeaderProps<T>) => {
  const wrapperRef = useRef<HTMLTableCellElement>(null);
  const isOpen = openFilterColumn === header.id;

  useClickOutside(
    wrapperRef,
    () => {
      if (isOpen) setOpenFilterColumn(null);
    },
    ["th"]
  );

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (header.column.getCanFilter() || header.column.getCanSort())
      setOpenFilterColumn(isOpen ? null : header.id);
  };

  const setIsOpen = (value: boolean) => {
    setOpenFilterColumn(value ? header.id : null);
  };

  const memoizedFilterMenu = useMemo(() => {
    return (
      <FilterMenu
        table={table}
        header={header}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }, [isOpen, header, table]);

  return (
    <th
      key={header.id}
      onClick={handleClick}
      className={clsx(
        "border-b border-b-boundsPurple-400 bg-boundsPurple-500 p-[10px]",
        header.column.columnDef.enableColumnFilter ||
          header.column.columnDef.enableSorting
          ? "cursor-pointer"
          : "cursor-default"
      )}
      style={{
        width: header.column.columnDef.size || "auto",
        minWidth: header.column.columnDef.minSize || "auto",
        maxWidth: header.column.columnDef.maxSize || "auto",
      }}
    >
      <div className="flex justify-between items-center gap-2 text-white select-none">
        <div className="font-bold">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        {(header.column.columnDef.enableColumnFilter ||
          header.column.columnDef.enableSorting) && (
          <div className="overflow-visible">
            <FilterIcon />
          </div>
        )}
      </div>
      <div ref={wrapperRef} className="relative">
        {memoizedFilterMenu}
      </div>
    </th>
  );
};

export default FilterHeader;
