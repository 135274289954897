import React from "react";
import { SVGProps } from "react";

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="M7 11a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H8a1 1 0 0 0-1 1ZM1 0a1 1 0 0 0 0 2h16a1 1 0 1 0 0-2H1Zm2 6a1 1 0 0 0 1 1h10a1 1 0 1 0 0-2H4a1 1 0 0 0-1 1Z"
    />
  </svg>
);
export default FilterIcon;
