import React, { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import SearchSelect from "../../../molecules/Sidebar/Search/SearchSelect";
import { siteAddressInputs } from "../../../molecules/Sidebar/Search/SearchInputs";
import {
  ISearchFormValues,
  SearchAddressFields,
  SearchRegionFields,
} from "../../../../types/search";
import useDebounce from "../../../../hooks/useDebounce";
import { useOwnerAddressSuggestions } from "../../../../hooks/useOwnerAddressSuggestions";
import { OwnerAddressFields } from "../../../../types/suggestions";

const AddressSearch = ({ isSubmitting }: { isSubmitting: boolean }) => {
  const { control, setValue } = useFormContext<ISearchFormValues>();
  const formValues = useWatch({ control });

  const [streetInput, setStreetInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [zipInput, setZipInput] = useState("");

  const debouncedStreet = useDebounce(streetInput, 300);
  const debouncedCity = useDebounce(cityInput, 300);
  const debouncedZip = useDebounce(zipInput, 300);

  const selectedState = formValues[SearchRegionFields.State]?.value || "KS";
  const selectedCounty = useMemo(
    () =>
      (formValues[SearchRegionFields.County]
        ?.map((c) => c?.value)
        .filter(Boolean) as string[]) || [],
    [formValues[SearchRegionFields.County]]
  );

  const {
    suggestions: streetSuggestions,
    isLoading: isLoadingStreetSuggestions,
  } = useOwnerAddressSuggestions({
    state: selectedState,
    county: selectedCounty,
    field: OwnerAddressFields.street,
    partial: debouncedStreet,
  });

  const { suggestions: citySuggestions, isLoading: isLoadingCitySuggestions } =
    useOwnerAddressSuggestions({
      state: selectedState,
      county: selectedCounty,
      field: OwnerAddressFields.city,
      partial: debouncedCity,
    });

  const { suggestions: zipSuggestions, isLoading: isLoadingZipSuggestions } =
    useOwnerAddressSuggestions({
      state: selectedState,
      county: selectedCounty,
      field: OwnerAddressFields.zip,
      partial: debouncedZip,
    });

  const handleInputChange = (field: string, value: string) => {
    if (field === SearchAddressFields.SiteAddr) {
      setStreetInput(value);
    } else if (field === SearchAddressFields.SiteCity) {
      setCityInput(value);
    } else if (field === SearchAddressFields.SiteZIP) {
      setZipInput(value);
    }
  };

  return (
    <>
      {siteAddressInputs.map((searchInput, index) => (
        <React.Fragment key={searchInput.name}>
          {index === 0 && <h3 className="text-2xl mb-0">Address</h3>}
          <SearchSelect
            key={searchInput.name}
            searchInput={{
              ...searchInput,
              options:
                searchInput.name === SearchAddressFields.SiteAddr
                  ? streetSuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : searchInput.name === SearchAddressFields.SiteCity
                  ? citySuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : searchInput.name === SearchAddressFields.SiteZIP
                  ? zipSuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : [],
            }}
            control={control}
            isSubmitting={isSubmitting}
            rules={
              searchInput.required
                ? { required: "This field is required" }
                : undefined
            }
            setValue={setValue}
            onInputChange={(value) =>
              handleInputChange(searchInput.name, value)
            }
            isLoadingOptions={
              searchInput.name === SearchAddressFields.SiteAddr
                ? isLoadingStreetSuggestions
                : searchInput.name === SearchAddressFields.SiteCity
                ? isLoadingCitySuggestions
                : searchInput.name === SearchAddressFields.SiteZIP
                ? isLoadingZipSuggestions
                : false
            }
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default AddressSearch;
