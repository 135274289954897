import React, { useMemo, useState } from "react";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { getMortgageRateAnalysis } from "../../../../services/mortgage";
import {
  IRateAnalysis,
  IRateAnalysisFetchParams,
} from "../../../../types/mortgage";
import {
  EmptyFetchResponse,
  getDefaultFetchParams,
  IFetchResponse,
} from "../../../../types/table";
import { formatDate } from "../../../../utils/date";
import { formatInterestRate, formatMoney } from "../../../../utils/string";
import Title from "../../../atoms/Title";
import InfiniteScrollTable from "../../../molecules/InfiniteScrollTable";

const MortgageAnalysis = ({ state }: { state: string }) => {
  const [queryParams] = useSearchParams();
  const queryParcelLid = queryParams.get("parcel_lid");
  const fetchParams = useMemo(
    () => ({
      ...getDefaultFetchParams(),
      state,
      parcel_lid: queryParcelLid,
    }),
    [state, queryParcelLid]
  );

  const dispatch = useDispatch();

  const handleClick = (parcel_lid: string) => {
    dispatch(setSelectedParcelLId(parcel_lid));
    window.open("/", "_blank", "noopener,noreferrer");
  };

  const loadRateAnalysis = async (
    params: IRateAnalysisFetchParams,
    signal: AbortSignal
  ): Promise<IFetchResponse<IRateAnalysis>> => {
    try {
      return await getMortgageRateAnalysis(params, signal);
    } catch (error: unknown) {
      if (error instanceof Error && error?.name != "AbortError") {
        console.error("Failed to fetch rate analysis data:", error);
      }
    }
    return EmptyFetchResponse;
  };

  const columns = useMemo<ColumnDef<IRateAnalysis>[]>(
    () => [
      {
        accessorKey: "issue",
        header: "Map",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const value = getValue() as string;
          const parcel_lid = row.original.parcel_lid;
          if (!value && parcel_lid) {
            return (
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => handleClick(parcel_lid)}
              >
                <FontAwesomeIcon className="question-mark" icon={faMap} />
              </div>
            );
          }
          return <span className="text-black">N/A</span>;
        },
      },
      {
        accessorKey: "type",
        sortField: "type",
        header: "Type",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "date",
        id: "time_since",
        sortField: "date",
        invertSorting: true,
        header: "Time Since",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          if (!value) {
            return <>{"No Data"}</>;
          }
          const today = new Date();
          const date = new Date(value);
          const displayValue =
            Math.floor(
              (today.getTime() - date.getTime()) / (24 * 60 * 60 * 1000)
            ) + " days";

          return <>{displayValue}</>;
        },
      },
      {
        accessorKey: "loan_institution",
        sortField: "loan_institution",
        header: "Loan Institution",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "borrower",
        sortField: "borrower",
        header: "Borrower",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "county",
        sortField: "county",
        header: "County",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "date",
        header: "Date",
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return <>{formatDate(value)}</>;
        },
      },
      {
        accessorKey: "mortgage_amount",
        sortField: "mortgage_amount",
        header: "Mortgage",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return value ? formatMoney(value) : "No Data";
        },
      },
      {
        accessorKey: "interest_rate",
        additionalFields: ["exact"],
        sortField: "interest_rate",
        header: "Interest Rate",
        enableColumnFilter: false,
        enableSorting: true,
        invertSorting: true,
        cell: ({ getValue, row }) => {
          const value = getValue() as string;
          const formattedRate = formatInterestRate(value);
          const exact = row.original.exact;
          return (
            <>
              <div
                className={clsx(
                  "font-medium",
                  formattedRate.includes("-")
                    ? "text-red-500"
                    : "text-green-500"
                )}
              >
                {formattedRate}
              </div>
              <div className="text-xs">
                {exact ? "(Actual)" : "(Estimated)"}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="mt-4 p-4 border-4 border-boundsPurple-500 bg-boundsPurple-600 py-6 px-10 rounded-[30px]">
      <Title label="Mortgage Rate Analysis" />
      <InfiniteScrollTable
        className="my-4 min-h-80 max-h-[calc(75vh)]"
        columns={columns}
        fetchData={loadRateAnalysis}
        fetchParams={fetchParams}
      />
    </div>
  );
};

export default MortgageAnalysis;
