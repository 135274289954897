import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { getUcc, getUccFile } from "../../../../services/ucc";
import {
  EmptyFetchResponse,
  getDefaultFetchParams,
  IFetchResponse,
} from "../../../../types/table";
import { IUccData, IUccFetchParams } from "../../../../types/ucc";
import Title from "../../../atoms/Title";
import InfiniteScrollTable from "../../../molecules/InfiniteScrollTable";
import { getUccColumns } from "./uccColumns";

const UccTable = ({ state }: { state: string }) => {
  const [queryParams] = useSearchParams();
  const queryParcelLid = queryParams.get("parcel_lid");
  const fetchParams = useMemo(
    () => ({
      ...getDefaultFetchParams(),
      state,
      parcel_lid: queryParcelLid,
    }),
    [state, queryParcelLid]
  );

  const loadUccData = async (
    params: IUccFetchParams,
    signal: AbortSignal
  ): Promise<IFetchResponse<IUccData>> => {
    try {
      return await getUcc(params, signal);
    } catch (error: unknown) {
      if (error instanceof Error && error?.name != "AbortError") {
        console.error("Error fetching UCC data", error);
      }
      return EmptyFetchResponse;
    }
  };

  const columns = useMemo(() => getUccColumns(state), [state]);

  return (
    <div className="mt-4 p-4 border-4 border-boundsPurple-500 bg-boundsPurple-600 py-6 px-10 rounded-[30px]">
      <Title label="UCC Search" />
      <InfiniteScrollTable
        className="my-4 min-h-80 max-h-[calc(75vh)]"
        columns={columns}
        fetchData={loadUccData}
        fetchParams={fetchParams}
      />
    </div>
  );
};

export default UccTable;
