import React, { ReactElement, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import clsx from "clsx";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import {
  CSSObjectWithLabel,
  GroupBase,
  MultiValueProps,
  StylesConfig,
} from "react-select";
import { IOption } from "../../../types/search";
import "../../atoms/Select/Select.scss";
import getStylesConfig from "../../atoms/Select/SelectStyles";

export interface MultiFilterSelect
  extends CreatableProps<IOption, boolean, GroupBase<IOption>> {
  control: Control<any, any, any>;
  label: string;
  name: string;
  variant?: "primary" | "secondary";
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const MultiFilterSelect = React.forwardRef<CreatableSelect, MultiFilterSelect>(
  (props, ref): ReactElement => {
    const {
      control,
      label,
      name,
      variant = "primary",
      styles = {},
      placeholder,
      isMulti = false,
    } = props;

    const mergedStyles: StylesConfig<IOption, boolean, GroupBase<IOption>> = {
      ...getStylesConfig(variant),
      ...styles,
      multiValue: (
        base: CSSObjectWithLabel,
        props: MultiValueProps<IOption, boolean, GroupBase<IOption>>
      ) => ({
        ...base,
        background: "#6d5a68",
        paddingLeft: "2px",
        ...(styles.multiValue ? styles.multiValue(base, props) : {}),
      }),
      multiValueRemove: (
        base: CSSObjectWithLabel,
        props: MultiValueProps<IOption, boolean, GroupBase<IOption>>
      ) => ({
        ...base,
        display: "inherit",
        ...(styles.multiValueRemove
          ? styles.multiValueRemove(base, props)
          : {}),
      }),
      container: (base: CSSObjectWithLabel) => ({
        ...base,
        display: "flex",
        flexDirection: "column",
      }),
      control: (base: CSSObjectWithLabel) => ({
        ...base,
        display: "flex",
        backgroundColor: "none",
        boxShadow: "none",
        minHeight: "auto",
        border: "none",
        borderRadius: 0,
        paddingBottom: 8,
        borderBottom: "1px solid #f2c94c",
      }),
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        position: "static",
        maxHeight: "200px",
        overflowY: "auto",
        zIndex: 10,
        background: "#cac4d0",
        margin: "5px 0",
        color: "#000",
        borderEndStartRadius: 4,
        borderEndEndRadius: 4,
        "svg>path": {
          fill: "#000",
        },
      }),
      noOptionsMessage: (base: CSSObjectWithLabel) => ({
        ...base,
        textAlign: "start",
      }),
    };

    const [options, setOptions] = useState<IOption[]>([]);

    return (
      <div className="flex flex-col relative text-start">
        <div
          className={clsx(
            "min-w-32 xl:min-w-40 pt-2",
            "rounded-t-md  relative",
            variant === "primary" && "bg-boundsPurple-150",
            variant === "secondary" &&
              "bg-boundsGray-100 border-b  border-white",
            props.isDisabled && "opacity-50",
            props.className
          )}
        >
          <div className="">
            <label
              htmlFor={name}
              className={clsx(
                "text-xs ",
                variant === "secondary" && "text-black"
              )}
            >
              {label}
            </label>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isClearable
                  isMulti={isMulti}
                  options={options}
                  value={field.value || (isMulti ? [] : "")}
                  onChange={(newValue) => {
                    field.onChange(isMulti ? newValue : newValue || "");
                    setTimeout(() => {
                      setOptions((prev) => [...prev]);
                    }, 0);
                  }}
                  onCreateOption={(inputValue) => {
                    const newOption = createOption(inputValue);
                    setOptions((prev) => [...prev, newOption]);

                    field.onChange(
                      isMulti ? [...(field.value || []), newOption] : newOption
                    );
                  }}
                  styles={mergedStyles}
                  placeholder={placeholder}
                  formatCreateLabel={(userInput) => `Search for ${userInput}`}
                  menuIsOpen={true}
                  menuPlacement="auto"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
);

MultiFilterSelect.displayName = "MultiFilterSelect";

export default MultiFilterSelect;
