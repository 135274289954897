import axiosBalancer from "../api";
import { IMapSearchFetchParams, ParcelResult } from "../types/search";
import { EmptyFetchResponse, IFetchResponse } from "../types/table";

export const getParcel = async (
  state: string,
  parcel_lid: string
): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/parcel",
      method: "get",
      params: { state, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};


export const getParcelSearch = async (
  params: IMapSearchFetchParams, signal: AbortSignal
): Promise<IFetchResponse<ParcelResult>> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/parcel/search",
      method: "post",
      data: params,
      signal
    });
    return response.data as IFetchResponse<ParcelResult>;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return EmptyFetchResponse;
  }
};

export const getExtendedParcel = async (
  state: string,
  parcel_lid: string
): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/parcel/extended",
      method: "get",
      params: { state, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};
