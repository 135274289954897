import React, { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { getParcelSearch } from "../../../../services/parcel";
import {
  IMapSearchFetchParams,
  IMapSearchResult,
  IOption,
  ISearchFormValues,
  OwnerSearchType,
  SearchAddressFields,
  SearchAdvancedFields,
  SearchFieldIdName,
  SearchOwnerFields,
  SearchRegionFields,
} from "../../../../types/search";
import {
  EmptyFetchResponse,
  getDefaultFetchParams,
  IFetchResponse,
  SORT_ORDER,
} from "../../../../types/table";
import getCounties from "../../../../utils/counties";
import Button from "../../../atoms/Button";
import OwnerSearchToggle from "../../../features/Sidebar/OwnerSearchToggle";
import Collapsible from "../../Collapsible";
import InfiniteScrollTable from "../../InfiniteScrollTable";
import {
  advancedInputs,
  regionInputs,
  searchFormDefaultValues,
  siteAddressInputs,
} from "./SearchInputs";
import SearchParcelSelect from "./SearchParcelSelect";
import SearchSelect from "./SearchSelect";
import { getSearchColumns } from "./searchColumns";
import AddressSearch from "../../../features/Sidebar/AddressSearch";

const Search = ({ closeWidget }: { closeWidget: () => void }) => {
  const { isMarketIntelAdmin, isMarketIntelOps } = useAuth();
  const dispatch = useDispatch();
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [fetchParams, setFetchParams] = useState<IMapSearchFetchParams>(
    getDefaultFetchParams()
  );
  const [ownerSearchType, setOwnerSearchType] = useState<OwnerSearchType>(
    OwnerSearchType.OWNER_NAME
  );
  const methods = useForm<ISearchFormValues>({
    defaultValues: searchFormDefaultValues,
  });
  const { control, handleSubmit, reset, setValue } = methods;
  const formValues = useWatch({ control }) as ISearchFormValues;
  const {
    sidebarMenu: { setIsSubmenuOpen, isSubmenuOpen: isResultsOpen },
  } = useBoundsUI();
  const selectedState = formValues[SearchRegionFields.State]?.value || "KS";
  const columns = useMemo(
    () => getSearchColumns(isMarketIntelAdmin, isMarketIntelOps),
    [isMarketIntelAdmin, isMarketIntelOps]
  );

  const countyOptions: IOption[] = useMemo(() => {
    return getCounties(selectedState).map(({ County, value }) => ({
      label: County,
      value: value,
    }));
  }, [selectedState]);

  useEffect(() => {
    setIsSubmenuOpen(false);
  }, []);

  const cancelSearch = () => {
    reset();
    closeWidget();
  };

  const clearFilters = () => {
    reset({ ...searchFormDefaultValues });
  };

  const getMapSearchQuery = (): IMapSearchFetchParams => {
    const params: IMapSearchFetchParams = {
      state: formValues[SearchRegionFields.State]?.value || "KS",
      sorting: [
        {
          sort_by: "parcel_lid",
          sort_order: SORT_ORDER.ASC,
        },
      ],
      pagination: {
        page: 1,
        page_size: 10,
        include_count: false,
      },

      parcel_lid: formValues[SearchFieldIdName.ParcelLid]?.map(
        (option) => option.value
      ),
      parcel_apn: formValues[SearchFieldIdName.Parcel]?.map(
        (option) => option.value
      ),
      unique_taxapn: formValues[SearchFieldIdName.UniqueTax]?.map(
        (option) => option.value
      ),
      alternate_taxapn: formValues[SearchFieldIdName.AlternateTax]?.map(
        (option) => option.value
      ),
      SITE_ADDR: formValues[SearchAddressFields.SiteAddr].map(
        (option) => option.value
      ),
      SITE_CITY: formValues[SearchAddressFields.SiteCity].map(
        (option) => option.value
      ),
      SITE_ZIP: formValues[SearchAddressFields.SiteZIP].map(
        (option) => option.value
      ),
      county: formValues[SearchRegionFields.County]?.map(
        (option) => option.value
      ),
      section: formValues[SearchAdvancedFields.Section]?.map(
        (option) => option.value
      ),
      township: formValues[SearchAdvancedFields.Township]?.map(
        (option) => option.value
      ),
      range: formValues[SearchAdvancedFields.Range]?.map(
        (option) => option.value
      ),
      block_number: formValues[SearchAdvancedFields.Block]?.map(
        (option) => option.value
      ),
      lot_number: formValues[SearchAdvancedFields.Lot]?.map(
        (option) => option.value
      ),
    };

    if (ownerSearchType === "owner_name") {
      params.OWNER_1_FIRST = formValues[SearchOwnerFields.OWNER_1_FIRST].map(
        (option) => option.value
      );
      params.OWNER_1_LAST = formValues[SearchOwnerFields.OWNER_1_LAST].map(
        (option) => option.value
      );
    }
    if (ownerSearchType === "business_name") {
      params.OWNER_NAME_1 = formValues[SearchOwnerFields.OWNER_NAME_1].map(
        (option) => option.value
      );
    }

    return params;
  };

  const loadParcelData = async (
    params: IMapSearchFetchParams,
    signal: AbortSignal
  ): Promise<IFetchResponse<IMapSearchResult>> => {
    try {
      setIsLoadingResults(true);
      const parcels: IFetchResponse<IMapSearchResult> = await getParcelSearch(
        params,
        signal
      );
      parcels.data = parcels.data.map((parcel: IMapSearchResult) => {
        return {
          ...parcel,
          description: `STR: ${
            parcel.section || parcel.township || parcel.range
              ? `${parcel.section ?? ""} ${parcel.township ?? ""} ${
                  parcel.range ?? ""
                }  `
              : "No Data"
          } ${
            parcel.block_number || parcel.lot_number
              ? ` B:${parcel.block_number ?? ""} L:${parcel.lot_number ?? ""} `
              : ""
          }`,
        };
      });
      return parcels;
    } catch (error) {
      console.error("Error fetching search results:", error);
      return EmptyFetchResponse;
    } finally {
      setIsLoadingResults(false);
    }
  };

  const onSubmit = () => {
    setFetchParams(getMapSearchQuery());
    setIsSubmenuOpen(true);
  };

  const onRowClick = (row: IMapSearchResult) => {
    dispatch(setSelectedParcelLId(row.parcel_lid));
  };

  return (
    <>
      {isResultsOpen ? (
        <InfiniteScrollTable
          className="my-4 min-h-80 max-h-[calc(90vh)]"
          columns={columns}
          fetchData={loadParcelData}
          fetchParams={fetchParams}
          onRowClick={onRowClick}
        />
      ) : (
        <>
          <h1 className="text-[32px]">Search</h1>
          <span
            onClick={clearFilters}
            className="ml-auto text-sm text-boundsYellow-50 cursor-pointer"
          >
            clear filters
          </span>
          <FormProvider {...methods}>
            <form
              className="mt-3 flex flex-col gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              {regionInputs.map((searchInput) => {
                return (
                  <SearchSelect
                    key={searchInput.name}
                    searchInput={{
                      ...searchInput,
                      options:
                        searchInput.name === SearchRegionFields.County
                          ? countyOptions
                          : searchInput.options,
                    }}
                    control={control}
                    isSubmitting={isLoadingResults}
                    rules={
                      searchInput.required
                        ? { required: "This field is required" }
                        : undefined
                    }
                    setValue={setValue}
                  />
                );
              })}
              <OwnerSearchToggle
                title={"Owner"}
                isSubmitting={isLoadingResults}
                setOwnerSearchType={setOwnerSearchType}
                ownerSearchType={ownerSearchType as string}
              />

              <AddressSearch isSubmitting={isLoadingResults} />

              <Collapsible title="Advanced Search">
                <SearchParcelSelect
                  control={control}
                  setValue={setValue}
                  isSubmitting={isLoadingResults}
                />
                {advancedInputs.map((searchInput) => {
                  return (
                    <SearchSelect
                      key={searchInput.name}
                      searchInput={searchInput}
                      control={control}
                      isSubmitting={isLoadingResults}
                      setValue={setValue}
                    />
                  );
                })}
              </Collapsible>

              <div className="flex gap-7">
                <Button
                  label={"Cancel"}
                  variant="secondary"
                  onClick={cancelSearch}
                />
                {isLoadingResults ? (
                  <Button
                    label={"Results"}
                    onClick={() => setIsSubmenuOpen(true)}
                  />
                ) : (
                  <Button
                    label={"Search"}
                    type={"submit"}
                    isLoading={isLoadingResults}
                  />
                )}
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default Search;
