import React, { useEffect, useMemo, useState } from "react";
import Select, { CSSObjectWithLabel, StylesConfig } from "react-select";
import { useFormContext, useWatch } from "react-hook-form";
import {
  ISearchFormValues,
  OwnerSearchType,
  SearchOwnerFields,
  SearchOwnerIdName,
  SearchRegionFields,
} from "../../../../types/search";
import getStylesConfig from "../../../atoms/Select/SelectStyles";
import {
  SEARCH_OWNER_OPTIONS,
  SEARCH_OWNER_ID_DICT,
} from "../../../molecules/Sidebar/Search/SearchInputs";
import SearchSelect from "../../../molecules/Sidebar/Search/SearchSelect";
import { useOwnerAddressSuggestions } from "../../../../hooks/useOwnerAddressSuggestions";
import { OwnerAddressFields } from "../../../../types/suggestions";
import useDebounce from "../../../../hooks/useDebounce";

const selectStyles = {
  ...getStylesConfig("primary"),
  dropdownIndicator: (styles: CSSObjectWithLabel) => ({
    ...styles,
    display: "initial",
    cursor: "pointer",
  }),
};

const OwnerSearchToggle = ({
  isSubmitting,
  title,
  setOwnerSearchType,
  ownerSearchType,
}: {
  isSubmitting: boolean;
  title?: string;
  ownerSearchType: string;
  setOwnerSearchType: React.Dispatch<React.SetStateAction<OwnerSearchType>>;
}) => {
  const defaultCategory = SEARCH_OWNER_OPTIONS[0];
  const [searchInputs, setSearchInputs] = useState(
    SEARCH_OWNER_ID_DICT[defaultCategory.value]
  );

  const { control, setValue } = useFormContext<ISearchFormValues>();
  const formValues = useWatch({ control });

  const [firstNameInput, setFirstNameInput] = useState("");
  const [lastNameInput, setLastNameInput] = useState("");
  const [businessNameInput, setBusinessNameInput] = useState("");

  const debouncedFirstName = useDebounce(firstNameInput, 500);
  const debouncedLastName = useDebounce(lastNameInput, 500);
  const debouncedBusinessName = useDebounce(businessNameInput, 500);

  const selectedState = formValues[SearchRegionFields.State]?.value || "KS";
  const selectedCounty = useMemo(
    () =>
      (formValues[SearchRegionFields.County]
        ?.map((c) => c?.value)
        .filter(Boolean) as string[]) || [],
    [formValues[SearchRegionFields.County]]
  );

  const {
    suggestions: firstNameSuggestions,
    isLoading: isLoadingFirstNameSuggestions,
  } = useOwnerAddressSuggestions({
    state: selectedState,
    county: selectedCounty,
    field: OwnerAddressFields.first_name,
    partial: debouncedFirstName,
  });

  const {
    suggestions: lastNameSuggestions,
    isLoading: isLoadingLastNameSuggestions,
  } = useOwnerAddressSuggestions({
    state: selectedState,
    county: selectedCounty,
    field: OwnerAddressFields.last_name,
    partial: debouncedLastName,
  });

  const {
    suggestions: businessNameSuggestions,
    isLoading: isLoadingBusinessNameSuggestions,
  } = useOwnerAddressSuggestions({
    state: selectedState,
    county: selectedCounty,
    field: OwnerAddressFields.business_name,
    partial: debouncedBusinessName,
  });

  const handleInputChange = (field: string, value: string) => {
    if (field === SearchOwnerFields.OWNER_1_FIRST) {
      setFirstNameInput(value);
    } else if (field === SearchOwnerFields.OWNER_1_LAST) {
      setLastNameInput(value);
    } else if (field === SearchOwnerFields.OWNER_NAME_1) {
      setBusinessNameInput(value);
    }
  };

  useEffect(() => {
    if (ownerSearchType) {
      setSearchInputs(
        SEARCH_OWNER_ID_DICT[ownerSearchType as SearchOwnerIdName] || []
      );
    }
  }, [ownerSearchType]);

  return (
    <div>
      {title && <h3 className="text-2xl mb-4">{title}</h3>}
      <div className="min-w-32 xl:min-w-40 rounded-t-md py-2 px-4 border-b relative bg-boundsPurple-150 border-boundsYellow-50 mb-1">
        <Select
          name="searchIdCategory"
          className="select-container"
          styles={selectStyles as StylesConfig}
          options={SEARCH_OWNER_OPTIONS}
          defaultValue={defaultCategory}
          onChange={(category) =>
            setOwnerSearchType(
              (category as { label: string; value: OwnerSearchType }).value
            )
          }
          isSearchable={false}
        />
      </div>

      <div className="flex flex-col gap-1">
        {searchInputs?.map((searchInput) => (
          <SearchSelect
            key={searchInput.name}
            searchInput={{
              ...searchInput,
              options:
                searchInput.name === SearchOwnerFields.OWNER_1_FIRST
                  ? firstNameSuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : searchInput.name === SearchOwnerFields.OWNER_1_LAST
                  ? lastNameSuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : searchInput.name === SearchOwnerFields.OWNER_NAME_1
                  ? businessNameSuggestions.map((name) => ({
                      value: name,
                      label: name,
                    }))
                  : [],
            }}
            control={control}
            isSubmitting={isSubmitting}
            setValue={setValue}
            onInputChange={(value) =>
              handleInputChange(searchInput.name, value)
            }
            isLoadingOptions={
              searchInput.name === SearchOwnerFields.OWNER_1_FIRST
                ? isLoadingFirstNameSuggestions
                : searchInput.name === SearchOwnerFields.OWNER_1_LAST
                ? isLoadingLastNameSuggestions
                : searchInput.name === SearchOwnerFields.OWNER_NAME_1
                ? isLoadingBusinessNameSuggestions
                : false
            }
          />
        ))}
      </div>
    </div>
  );
};

export default OwnerSearchToggle;
