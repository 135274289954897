import states from "../../../../utils/states";
import {
  ISearchFields,
  SearchAddressFields,
  SearchAdvancedFields,
  SearchFieldIdName,
  SearchOwnerFields,
  SearchOwnerIdName,
  SearchRegionFields,
} from "../../../../types/search";

export const SEARCH_PARCEL_ID_CATEGORY_OPTIONS = [
  { label: "Parcel #", value: SearchFieldIdName.Parcel },
  { label: "Unique Tax #", value: SearchFieldIdName.UniqueTax },
  { label: "Alternate Tax #", value: SearchFieldIdName.AlternateTax },
  { label: "ParcelLID", value: SearchFieldIdName.ParcelLid },
];

export const SEARCH_PARCEL_ID_DICT: Record<SearchFieldIdName, ISearchFields> = {
  [SearchFieldIdName.Parcel]: {
    label: "Parcel #",
    placeholder: "Search here",
    name: SearchFieldIdName.Parcel,
    isMulti: true,
    queryFieldName: "PARCEL_APN",
  },
  [SearchFieldIdName.UniqueTax]: {
    label: "Unique Tax #",
    placeholder: "Search here",
    name: SearchFieldIdName.UniqueTax,
    isMulti: true,
    queryFieldName: "UNIQUE_TAXAPN",
  },
  [SearchFieldIdName.AlternateTax]: {
    label: "Alternate Tax #",
    placeholder: "Search here",
    name: SearchFieldIdName.AlternateTax,
    isMulti: true,
    queryFieldName: "ALTERNATE_TAXAPN",
  },
  [SearchFieldIdName.ParcelLid]: {
    label: "ParcelLid",
    placeholder: "Search here",
    name: SearchFieldIdName.ParcelLid,
    isMulti: true,
    queryFieldName: "PARCEL_LID",
  },
};

export const searchIdInputs: ISearchFields[] = [
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.Parcel],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.UniqueTax],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.AlternateTax],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.ParcelLid],
];

export const regionInputs: ISearchFields[] = [
  {
    label: "State",
    placeholder: "Search here",
    name: SearchRegionFields.State,
    options: states,
    isMulti: false,
    queryFieldName: "SITE_STATE",
    defaultValue: { label: "Kansas (KS)", value: "KS" },
    required: true,
    allowCreateOption: false,
  },
  {
    label: "County",
    placeholder: "Search county",
    name: SearchRegionFields.County,
    isMulti: true,
    prevOption: SearchRegionFields.State,
    queryFieldName: "COUNTY",
    allowCreateOption: false,
  },
];
export const siteAddressInputs: ISearchFields[] = [
  {
    label: "Street",
    placeholder: "Search street",
    name: SearchAddressFields.SiteAddr,
    isMulti: true,
    queryFieldName: "MAIL_ADDR",
  },
  {
    label: "City",
    placeholder: "Search city",
    name: SearchAddressFields.SiteCity,
    isMulti: true,
    queryFieldName: "MAIL_ADDR",
  },
  {
    label: "Zip",
    placeholder: "Search zip",
    name: SearchAddressFields.SiteZIP,
    isMulti: true,
    queryFieldName: "MAIL_ADDR",
  },
];

export const advancedInputs: ISearchFields[] = [
  {
    label: "Section",
    placeholder: "Search section",
    name: SearchAdvancedFields.Section,
    isMulti: true,
    prevOption: SearchAdvancedFields.Range,
    queryFieldName: "SECTION",
  },
  {
    label: "Township",
    placeholder: "Search township",
    name: SearchAdvancedFields.Township,
    isMulti: true,
    prevOption: SearchRegionFields.County,
    queryFieldName: "TOWNSHIP",
  },
  {
    label: "Range",
    placeholder: "Search range",
    name: SearchAdvancedFields.Range,
    isMulti: true,
    prevOption: SearchAdvancedFields.Township,
    queryFieldName: "RANGE",
  },
  {
    label: "Block",
    placeholder: "Search block",
    name: SearchAdvancedFields.Block,
    isMulti: true,
    prevOption: SearchAdvancedFields.Block,
    queryFieldName: "block_number",
  },
  {
    label: "Lot",
    placeholder: "Search lot",
    name: SearchAdvancedFields.Lot,
    isMulti: true,
    prevOption: SearchAdvancedFields.Lot,
    queryFieldName: "lot_number",
  },
];

export const searchFormDefaultValues = {
  //region
  [SearchRegionFields.State]: { label: "Kansas (KS)", value: "KS" },
  [SearchRegionFields.County]: [],
  //owner
  [SearchOwnerFields.OWNER_1_FIRST]: [],
  [SearchOwnerFields.OWNER_1_LAST]: [],
  [SearchOwnerFields.OWNER_NAME_1]: [],
  //address
  [SearchAddressFields.SiteAddr]: [],
  [SearchAddressFields.SiteCity]: [],
  [SearchAddressFields.SiteZIP]: [],
  //advanced
  [SearchFieldIdName.Parcel]: [],
  [SearchFieldIdName.UniqueTax]: [],
  [SearchFieldIdName.AlternateTax]: [],
  [SearchFieldIdName.ParcelLid]: [],
  [SearchAdvancedFields.Township]: [],
  [SearchAdvancedFields.Range]: [],
  [SearchAdvancedFields.Section]: [],
  [SearchAdvancedFields.Block]: [],
  [SearchAdvancedFields.Lot]: [],
};

export const SEARCH_OWNER_OPTIONS = [
  { label: "Owner Name", value: SearchOwnerIdName.OwnerName },
  { label: "Business Name", value: SearchOwnerIdName.BusinessName },
];

export const SEARCH_OWNER_ID_DICT: Record<SearchOwnerIdName, ISearchFields[]> =
  {
    [SearchOwnerIdName.OwnerName]: [
      {
        label: "First Name",
        placeholder: "Search owner",
        name: SearchOwnerFields.OWNER_1_FIRST,
        isMulti: true,
      },
      {
        label: "Last Name",
        placeholder: "Search owner",
        name: SearchOwnerFields.OWNER_1_LAST,
        isMulti: true,
      },
    ],
    [SearchOwnerIdName.BusinessName]: [
      {
        label: "Business Name",
        placeholder: "Search owner",
        name: SearchOwnerFields.OWNER_NAME_1,
        isMulti: true,
      },
    ],
  };
