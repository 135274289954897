export enum OwnerAddressFields {
  first_name = "first_name",
  last_name = "last_name",
  business_name = "business_name",
  street = "street",
  city = "city",
  zip = "zip",
}

export type OwnerAddressSuggestionsRequest = {
  state: string;
  county?: string[];
  field: OwnerAddressFields;
  partial?: string;
};

export type OwnerAddressSuggestionsResponse = {
  suggestions: string[];
};
