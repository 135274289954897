import React, { useEffect, useState } from "react";
import unsplash from "../../assets/images/unsplash.jpg";
import logo from "../../assets/images/Logo.png";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import { mfaSetupSchema } from "../Login/login.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InferType } from "yup";
import { useAuth } from "../../hooks/useAuth";
import QRCodeGenerator from "../../components/features/QRCodeGenerator/QRCodeGenerator";
import { confirmMFASetup, verifyMFA } from "../../services/auth";
import { ToastOptions, toast } from "react-toastify";
import { ToastSettings } from "../../utils/toast";
import Skeleton from "../../components/atoms/Skeleton";
import { useNavigate } from "react-router-dom";
import { IAuthStatus } from "../../types/auth.types";

const MFAPage = () => {
  const navigate = useNavigate();
  const { enableMFA, isMFAActive, setIsMFAActive, validateMFA, resetMFA } =
    useAuth();
  const [loadingUri, setLoadingUri] = useState(true);
  const [loadingSetup, setLoadingSetup] = useState(false);
  const [provisioningUri, setProvisioningUri] = useState<string | null>(null);
  const [loadingReset, setLoadingReset] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mfaSetupSchema),
  });

  const enableMFASetup = async (totp_code: string) => {
    try {
      const response = await confirmMFASetup(totp_code);

      if (!response) {
        toast.error(
          "Failed to confirm MFA setup.",
          ToastSettings as ToastOptions
        );
        setLoadingSetup(false);
        return false;
      }

      toast.success(response.message, ToastSettings as ToastOptions);
      setLoadingSetup(false);
      setIsMFAActive(true);
      navigate("/login");
      return true;
    } catch (error) {
      setLoadingSetup(false);
      toast.error(
        "An unexpected error occurred while confirming MFA.",
        ToastSettings as ToastOptions
      );
      return false;
    }
  };

  const onValidateMFA = async (
    totpCode: string
  ): Promise<IAuthStatus | null> => {
    const success = await validateMFA(totpCode);
    setLoadingSetup(false);
    return success;
  };

  const onSubmit = async (values: InferType<typeof mfaSetupSchema>) => {
    const { totp_code } = values;
    setLoadingSetup(true);
    if (isMFAActive) {
      onValidateMFA(totp_code);
    } else enableMFASetup(totp_code);
  };

  const handleResetMFA = async () => {
    setLoadingReset(true);
    const success = await resetMFA();
    setLoadingReset(false);
  };

  useEffect(() => {
    if (!isMFAActive) {
      const handleEnableMFA = async () => {
        const uri = await enableMFA();
        if (uri) {
          setProvisioningUri(uri);
        }
        setLoadingUri(false);
      };
      handleEnableMFA();
    }
  }, [isMFAActive]);

  return (
    <div
      className="bg-boundsPurple-150 h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${unsplash})` }}
    >
      <div className="absolute inset-0 bg-boundsPurple-500 bg-opacity-75">
        <section className="w-full h-full flex justify-center items-center">
          <form
            className="border border-boundsYellow-50 bg-boundsPurple-700 pt-5 pb-5 px-8 rounded-2xl shadow-xl flex flex-col gap-4 min-w-[310px] lg:w-[390px] lg:pt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <img className="mx-auto h-113 w-257" src={logo}></img>
            {!isMFAActive && (
              <>
                {loadingUri ? (
                  <Skeleton height={200} width={200} className="mx-auto" />
                ) : (
                  provisioningUri && (
                    <QRCodeGenerator
                      url={provisioningUri as string}
                      label="You must enable MFA to use this site. Scan the QR code below with your preferred MFA application and enter the code below to confirm successful MFA setup."
                    />
                  )
                )}
              </>
            )}
            <div>
              <Input
                label={"MFA Code"}
                type="text"
                {...register("totp_code")}
              />
              {errors.totp_code && (
                <span className="font-bold text-red-500 text-[12px]">
                  {errors.totp_code.message}
                </span>
              )}
            </div>
            <Button
              className="w-full mt-2"
              label={"Continue"}
              type="submit"
              isLoading={loadingSetup}
            />
            <button
              className="text-amber-100 text-[12px] block text-center"
              onClick={handleResetMFA}
              disabled={loadingReset}
            >
              Lost MFA?{" "}
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default MFAPage;
