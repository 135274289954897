import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);
// May need these / similar in the future
/*Chart.register(LineElement);
Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(Filler);*/

interface IPieChartData {
  labels?: string[];
  values: number[];
  colors?: string[];
}

const PieChart = ({
  labels,
  values,
  colors = ["#0263ff", "#f97223", "#8e30ff"],
}: IPieChartData) => {
  const total = values.reduce((sum, val) => sum + val, 0);

  return (
    <Pie
      style={{ minHeight: "400px" }}
      data={{
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        layout: { padding: { top: 5 } },
        plugins: {
          legend: {
            position: "right",
            labels: {
              color: "white",
              padding: 25,
              boxHeight: 25,
              boxWidth: 25,
              font: {
                size: 14,
              },
            },
          },
          datalabels: {
            display: true,
            color: "#ffffff",
            textStrokeWidth: 0.5,
            font: { size: 14 },
            formatter: (value: number) => {
              const percentage = ((value / total) * 100).toFixed(1);
              return `${percentage}%`;
            },
          },
        },
      }}
    />
  );
};

export default PieChart;
