import React, { useEffect, useRef, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Updater,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import InfiniteScroll from "react-infinite-scroll-component";
import usePrevious from "../../../hooks/usePrevious";
import {
  IFetchParams,
  IFetchResponse,
  IFiltering,
  SORT_ORDER,
} from "../../../types/table";
import Skeleton from "../../atoms/Skeleton";
import FilterHeader from "../FilterHeader";
import { FormProvider, useForm } from "react-hook-form";
import { ISearchFormValues } from "../../../types/search";

interface InfiniteScrollTableProps<T> {
  className?: string;
  columns: ColumnDef<T>[];
  fetchData: (
    params: IFetchParams,
    signal: AbortSignal
  ) => Promise<IFetchResponse<T>>;
  fetchParams: IFetchParams;
  onRowClick?: (row: T) => void;
}

export default function InfiniteScrollTable<T>({
  className,
  columns,
  fetchData,
  fetchParams,
  onRowClick = undefined,
}: InfiniteScrollTableProps<T>) {
  const [data, setData] = useState<T[]>([]);
  const [currentFetchParams, setCurrentFetchParams] =
    useState<IFetchParams>(fetchParams);
  const previousFetchParams = usePrevious(currentFetchParams);
  const [hasMore, setHasMore] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [openFilterColumn, setOpenFilterColumn] = useState<string | null>(null);
  const [lastTouchedField, setLastTouchedField] = useState<string | null>(null);

  const methods = useForm<ISearchFormValues>({
    mode: "onChange",
  });
  const { watch, reset } = methods;

  const resetOtherFilters = (currentFilter: string) => {
    setLastTouchedField(currentFilter);
    reset(
      (prevValues) =>
        Object.fromEntries(
          Object.entries(prevValues).map(([key, value]) => [
            key,
            key === currentFilter ? value : "",
          ])
        ) as ISearchFormValues
    );
  };

  const onColumnFiltersChange = (
    updaterOrValue: Updater<ColumnFiltersState>
  ) => {
    setCurrentFetchParams((prev) => {
      const newColumnFilters =
        typeof updaterOrValue === "function"
          ? updaterOrValue(
              prev.filtering
                ? prev.filtering.map((f) => ({
                    id: f.filter_column,
                    value: f.filter_value1,
                  }))
                : []
            )
          : updaterOrValue;

      const newFiltering = newColumnFilters.map((filter) => ({
        filter_column: filter.id,
        filter_value1: (filter.value as IFiltering).filter_value1,
      }));
      return {
        ...prev,
        filtering: newFiltering,
        pagination: { ...prev.pagination, page: 1 },
      };
    });
  };

  const onSortingChange = (updaterOrValue: Updater<SortingState>) => {
    setCurrentFetchParams((prev) => {
      const newSortingState =
        typeof updaterOrValue === "function"
          ? updaterOrValue(
              prev.sorting
                ? prev.sorting.map((s) => ({
                    id: s.sort_by,
                    desc: s.sort_order === SORT_ORDER.DESC,
                  }))
                : []
            )
          : updaterOrValue;

      const newSorting = newSortingState.map((sort) => ({
        sort_by: sort.id,
        sort_order: sort.desc ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      }));

      return {
        ...prev,
        sorting: newSorting,
        pagination: { ...prev.pagination, page: 1 },
      };
    });
  };

  const reactTable = useReactTable({
    data,
    columns,
    state: {
      sorting:
        currentFetchParams.sorting?.map((s) => ({
          id: s.sort_by,
          desc: s.sort_order === SORT_ORDER.DESC,
        })) || [],
      columnFilters:
        currentFetchParams.filtering?.map((f) => ({
          id: f.filter_column,
          value: f.filter_value1,
        })) || [],
    },
    onColumnFiltersChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadNextPage = () => {
    setCurrentFetchParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, page: prev.pagination.page + 1 },
    }));
  };

  useEffect(() => {
    setCurrentFetchParams(fetchParams);
  }, [fetchParams]);

  useEffect(() => {
    const fetchTableData = async () => {
      if (abortControllerRef.current) abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      try {
        const { data: newData, total_count } = await fetchData(
          currentFetchParams,
          signal
        );
        if (!signal.aborted && newData) {
          const { page, page_size } = currentFetchParams.pagination;
          setData((prev) => (page === 1 ? newData : [...prev, ...newData]));
          if (total_count) {
            setHasMore((page - 1) * page_size + newData.length < total_count);
          } else {
            setHasMore(newData.length >= page_size);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Reset data when fetch params change, except for pagination
    const { pagination: prevPagination, ...restPrevious } =
      previousFetchParams || {};
    const { pagination: currentPagination, ...restCurrent } =
      currentFetchParams;

    if (!isEqual(restPrevious, restCurrent)) {
      setData([]);
      setHasMore(true);
    }

    fetchTableData();

    // Cleanup any pending fetch requests when component unmounts
    return () => {
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
  }, [currentFetchParams]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name) setLastTouchedField(name);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!lastTouchedField) return;

    resetOtherFilters(lastTouchedField);
  }, [lastTouchedField, reset]);

  return (
    <div
      className={clsx("overflow-auto rounded-3xl", className)}
      id="scroll-container"
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadNextPage}
        hasMore={hasMore}
        loader={<Skeleton width="100%" height={50} borderRadius={0} />}
        endMessage={
          <p className="border-y-[1px] border-boundsYellow-50 py-5 text-center">
            {data.length === 0 ? "No data available." : "No more results."}
          </p>
        }
        scrollableTarget="scroll-container"
      >
        <table className="w-auto min-w-full max-w-full border-b-[1px] border-boundsPurple-100 table-fixed">
          <FormProvider {...methods}>
            <thead>
              {reactTable.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="bg-boundsPurple-500 sticky top-0 z-10"
                >
                  {headerGroup.headers.map((header) => (
                    <FilterHeader
                      key={header.column.id}
                      table={reactTable}
                      header={header}
                      openFilterColumn={openFilterColumn}
                      setOpenFilterColumn={setOpenFilterColumn}
                    />
                  ))}
                </tr>
              ))}
            </thead>
          </FormProvider>
          <tbody className="bg-boundsGray-100 text-black">
            {reactTable.getRowModel().rows.length > 0 &&
              reactTable.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => onRowClick && onRowClick(row.original)}
                  className={clsx(
                    onRowClick && "cursor-pointer hover:bg-white"
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="p-2 border-[2px] border-boundsPurple-500"
                      style={{
                        width: cell.column.columnDef.size || "auto",
                        minWidth: cell.column.columnDef.minSize || "auto",
                        maxWidth: cell.column.columnDef.maxSize || "auto",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}
