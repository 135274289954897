import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components, MenuProps } from "react-select";

interface CustomMenuProps<T> extends MenuProps<T> {
  isLoading: boolean;
}

const CustomMenu = <T,>(props: CustomMenuProps<T>) => {
  const { isLoading, children } = props;

  return (
    <components.Menu {...props}>
      {isLoading ? (
        <div className="flex justify-center p-2">
          <FontAwesomeIcon icon={faSpinner} className="animate-pulse" />
          <span className="ml-2 text-sm">Loading...</span>
        </div>
      ) : (
        children
      )}
    </components.Menu>
  );
};

export default CustomMenu;
