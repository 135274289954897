import React, { ReactNode, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  checkAuthStatus,
  login as loginApi,
  logout as logoutApi,
  checkPasswordResetToken as checkPasswordResetTokenApi,
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
  initializeMFASetup,
  verifyMFA,
  requestMFAReset,
} from "../services/auth";
import { ToastOptions, toast } from "react-toastify";
import { AuthContext } from "../contexts/AuthContext";
import { ToastSettings } from "../utils/toast";
import { IAuthStatus, IMFAStatus } from "../types/auth.types";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("Anonymous");
  const [groups, setGroups] = useState<string[]>([]);
  const [isMFAActive, setIsMFAActive] = useState<boolean>(false);

  const updateLocalStorage = (authStatus: boolean) => {
    localStorage.setItem("isAuthenticated", authStatus.toString());
  };

  const handleStorageEvent = (event: StorageEvent) => {
    if (event.key === "isAuthenticated") {
      if (event.newValue === event.oldValue) return;

      checkAuth();

      const loggedStatus = event.newValue === "true";
      if (!loggedStatus && location.pathname !== "/login") {
        navigate("/login");
      } else if (loggedStatus && location.pathname === "/login") {
        navigate("/");
      }
    }
  };

  const setAuthStatus = (
    isAuthenticated: boolean,
    userId: number,
    username: string,
    groups: string[]
  ) => {
    setIsAuthenticated(isAuthenticated);
    setUserId(userId);
    setUsername(username);
    setGroups(groups);
    updateLocalStorage(isAuthenticated);
  };

  const login = async (
    username: string,
    password: string
  ): Promise<IMFAStatus | null> => {
    try {
      const response = await loginApi(username, password);
      if (!response) {
        toast.error(
          "Username or Password Incorrect",
          ToastSettings as ToastOptions
        );
        return null;
      }
      if (response.isMFAActive) {
        setIsMFAActive(response.isMFAActive);
      }
      return response;
    } catch (error) {
      toast.error(
        "An unexpected error occurred.",
        ToastSettings as ToastOptions
      );
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await logoutApi();
      if (response) {
        setAuthStatus(false, 0, "Anonymous", []);
      } else {
        toast.error("Error: Could not log out.", ToastSettings as ToastOptions);
      }
      return response;
    } catch (error) {
      toast.error("Error: Could not log out.", ToastSettings as ToastOptions);
      throw error;
    }
  };

  const checkAuth = useCallback(async () => {
    try {
      const authStatus = await checkAuthStatus();
      if (authStatus) {
        setAuthStatus(
          authStatus.is_authenticated,
          authStatus.id,
          authStatus.username,
          authStatus.groups
        );
      } else {
        toast.error("Error: Could not connect to authorization server.", {});
      }
    } catch (error) {
      toast.error("Error: Could not connect to authorization server.", {});
    }
  }, []);

  const checkPasswordResetToken = async (email: string, token: string) => {
    return await checkPasswordResetTokenApi(email, token).catch(() => false);
  };

  const forgotPassword = async (email: string) => {
    return forgotPasswordApi(email)
      .then(() => {
        toast.success(
          "Password reset link sent.",
          ToastSettings as ToastOptions
        );
        return true;
      })
      .catch(() => {
        toast.error(
          "An unexpected error occurred.",
          ToastSettings as ToastOptions
        );
        return false;
      });
  };

  const resetPassword = async (
    email: string,
    password: string,
    token: string
  ) => {
    return resetPasswordApi(email, password, token)
      .then(() => {
        toast.success(
          "Password reset successful.",
          ToastSettings as ToastOptions
        );
        return true;
      })
      .catch(() => {
        toast.error(
          "An unexpected error occurred.",
          ToastSettings as ToastOptions
        );
        return false;
      });
  };

  const isMarketIntelAdmin = () => {
    return groups.includes("Market Intel Admin");
  };

  const isMarketIntelOps = () => {
    return groups.includes("Market Intel Ops");
  };

  /* MFA */

  const enableMFA = async (): Promise<string | null> => {
    try {
      const response = await initializeMFASetup();

      if (!response) {
        return null;
      }

      return response.provisioning_uri;
    } catch (error) {
      toast.error(
        "An unexpected error occurred while initializing MFA.",
        ToastSettings as ToastOptions
      );
      return null;
    }
  };

  const validateMFA = async (totpCode: string): Promise<IAuthStatus | null> => {
    try {
      const response = await verifyMFA(totpCode);
      if (!response) {
        toast.error(
          "Invalid MFA code. Please try again.",
          ToastSettings as ToastOptions
        );
        return null;
      }
      if (response.is_authenticated) {
        setAuthStatus(
          response.is_authenticated,
          response.id,
          response.username,
          response.groups
        );
        return response;
      }

      return null;
    } catch (error) {
      toast.error(
        "An unexpected error occurred.",
        ToastSettings as ToastOptions
      );
      throw error;
    }
  };

  const resetMFA = async (): Promise<boolean> => {
    try {
      const response = await requestMFAReset();

      if (!response) {
        toast.error(
          "Error sending MFA reset email.",
          ToastSettings as ToastOptions
        );
        return false;
      }

      toast.success(
        response as unknown as string,
        ToastSettings as ToastOptions
      );
      return true;
    } catch (error) {
      toast.error(
        "An unexpected error occurred while requesting MFA reset.",
        ToastSettings as ToastOptions
      );
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const initializeAuth = async () => {
      await checkAuth();
      setIsLoading(false);
    };

    initializeAuth();
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [checkAuth]);

  return (
    <AuthContext.Provider
      value={{
        userId,
        groups,
        isAuthenticated,
        isMarketIntelAdmin,
        isMarketIntelOps,
        isLoading,
        login,
        logout,
        checkPasswordResetToken,
        forgotPassword,
        resetPassword,
        isMFAActive,
        setIsMFAActive,
        enableMFA,
        validateMFA,
        resetMFA,
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
