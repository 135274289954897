import axios, { AxiosError } from "axios";
import { getCookieValue } from "../utils/cookies";

const configFunction = (config: any) => {
  config.headers["X-CSRFToken"] = getCookieValue("csrftoken");
  return config;
};

const responseFunction = (response: any) => {
  return response;
};

const errorFunction = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    return Promise.reject(error);
  }
  return Promise.reject(new AxiosError("Unknown error"));
};

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
defaultInstance.interceptors.request.use(configFunction, errorFunction);
defaultInstance.interceptors.response.use(responseFunction, errorFunction);

export class AxiosBalancer {
  private static instance: AxiosBalancer | null = null;

  static instances = [
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }),
    axios.create({
      baseURL: process.env.REACT_APP_API_URL2,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }),
    axios.create({
      baseURL: process.env.REACT_APP_API_URL3,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ];

  static counter = [0, 0, 0];

  private constructor() {
    AxiosBalancer.instances.forEach((inst) => {
      inst.interceptors.request.use(configFunction, errorFunction);
      inst.interceptors.response.use(responseFunction, errorFunction);
    });
  }

  public static getInstance(): AxiosBalancer {
    if (!AxiosBalancer.instance) {
      AxiosBalancer.instance = new AxiosBalancer();
    }
    return AxiosBalancer.instance;
  }

  async request(params: any) {
    const index = AxiosBalancer.counter.findIndex(
      (count) => count === Math.min(...AxiosBalancer.counter)
    );
    AxiosBalancer.counter[index]++;
    const response = await AxiosBalancer.instances[index].request(params);
    AxiosBalancer.counter[index]--;
    return response;
  }
}

export default defaultInstance;
export const axiosBalancer = AxiosBalancer.getInstance();
