import React, { useEffect, useMemo } from "react";
import { faArrowDownAZ, faArrowUpAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header, Table } from "@tanstack/react-table";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import usePrevious from "../../../hooks/usePrevious";
import {
  IOption,
  ISearchFields,
  SearchFilterTable,
} from "../../../types/search";
import { SORT_ORDER } from "../../../types/table";
import { ComponentType } from "../../../utils/mortgage";
import DynamicInput from "../../features/DynamicInput";

interface FilterMenuProps<T> {
  table: Table<T>;
  header: Header<T, unknown>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const FilterMenu = <T,>({
  table,
  header,
  isOpen,
  setIsOpen,
}: FilterMenuProps<T>) => {
  const { watch, control, register, formState } = useFormContext();

  const filterInputConfig: ISearchFields = useMemo(() => {
    return {
      label: "Filter",
      placeholder: "Type here",
      name: `${header.column.id}_${SearchFilterTable.FilterValue1}`,
      isMulti: false,
      component: "MultiFilterSelect" as ComponentType,
      rules: undefined,
    };
  }, [header.column.id]);

  const filterValue = watch(filterInputConfig.name) as IOption;
  const prevFilterValue = usePrevious(filterValue);

  const isInverted = header.column.columnDef.invertSorting;
  const currentSort = header.column.getIsSorted();
  const isSortAtoZActive = isInverted
    ? currentSort === "desc"
    : currentSort === "asc";
  const isSortZtoAActive = isInverted
    ? currentSort === "asc"
    : currentSort === "desc";

  const handleSortClick = (order: SORT_ORDER) => {
    table.setSorting((prev) => {
      const currentSort = prev[0];
      const isSameColumn = currentSort && currentSort.id === header.column.id;
      const isInverted = header.column.columnDef.invertSorting;
      const newDesc = isInverted
        ? order === SORT_ORDER.ASC
        : order === SORT_ORDER.DESC;
      const isSameOrder = isSameColumn && currentSort.desc === newDesc;

      return isSameOrder ? [] : [{ id: header.column.id, desc: newDesc }];
    });
  };

  useEffect(() => {
    if (!isOpen || filterValue === undefined) return;

    const currentValue =
      typeof filterValue === "object" ? filterValue.value : filterValue;
    const previousValue =
      typeof prevFilterValue === "object"
        ? prevFilterValue?.value
        : prevFilterValue;
    if (currentValue === previousValue) return;

    table.setColumnFilters([
      {
        id: header.column.id,
        value: { filter_value1: currentValue || "" },
      },
    ]);
    setIsOpen(false);
  }, [filterValue, isOpen]);

  return (
    <div
      className={clsx(
        "absolute left-0 top-0 p-3 w-fit z-50 bg-boundsPurple-100 border border-boundsPurple-400 rounded h-auto",
        isOpen ? "block" : "hidden"
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col gap-[14px] items-start text-sm font-normal">
        {header.column.columnDef.enableSorting && (
          <div>
            <div
              className={clsx(
                "flex justify-between gap-2 items-center cursor-pointer whitespace-nowrap",
                isSortAtoZActive && "text-green-500"
              )}
              onClick={() => handleSortClick(SORT_ORDER.ASC)}
            >
              Sort A to Z
              <FontAwesomeIcon icon={faArrowDownAZ} />
            </div>
            <div
              className={clsx(
                "flex justify-between gap-2 items-center cursor-pointer whitespace-nowrap",
                isSortZtoAActive && "text-green-500"
              )}
              onClick={() => handleSortClick(SORT_ORDER.DESC)}
            >
              Sort Z to A
              <FontAwesomeIcon icon={faArrowUpAZ} />
            </div>
          </div>
        )}
        {header.column.columnDef.enableColumnFilter && (
          <DynamicInput
            {...filterInputConfig}
            register={register}
            name={filterInputConfig.name}
            control={control}
            component={filterInputConfig.component as ComponentType}
            errorMessage={
              formState.errors[filterInputConfig.name]?.message as string
            }
            rules={filterInputConfig.rules}
          />
        )}
      </div>
    </div>
  );
};

export default FilterMenu;
