import axiosBalancer from "../api";
import {
  OwnerAddressSuggestionsRequest,
  OwnerAddressSuggestionsResponse,
} from "../types/suggestions";

export const getOwnerAddressSuggestions = async (
  params: OwnerAddressSuggestionsRequest,
  signal: AbortSignal
): Promise<OwnerAddressSuggestionsResponse | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/suggestions/owner-address",
      method: "post",
      data: params,
      signal,
    });
    return response.data as OwnerAddressSuggestionsResponse;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return null;
  }
};
