import React, { useEffect } from "react";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import { ColumnDef } from "@tanstack/react-table";
import { SoilData } from "../../../../types/insights";
import Table from "../../../molecules/Table";
import { Doughnut } from "react-chartjs-2";
import { createHighlightRenderer } from "../../../../utils/soil";
import { SoilHighlightLayer } from "../../../../layers/Layers";

const columns: ColumnDef<SoilData>[] = [
  {
    accessorKey: "soil_code",
    header: "Soil Code",
    cell: (info) => {
      const soilCode = info.getValue() as string;
      const irrClass = info.row.original.irr_class;
      const color = `#${soilCode}${irrClass}`.slice(0, 7);

      return (
        <div className="flex items-center">
          <div
            style={{
              backgroundColor: color,
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              marginRight: "8px",
              border: "1px solid #000",
            }}
          ></div>
          <span>{soilCode}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "soil_description",
    header: "Soil Description",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "percent_field",
    header: "% of Field",
    cell: (info) => `${(info.getValue() as number).toFixed(2)}%`,
  },
  {
    accessorKey: "nccpi_score",
    header: "NCCPI Score",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "nccpi_corn",
    header: "NCCPI Corn",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "nccpi_soy",
    header: "NCCPI Soy",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "nccpi_cotton",
    header: "NCCPI Cotton",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "nccpi_sorghum",
    header: "NCCPI Sorghum",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "non_irr_class",
    header: "Non-IRR Class",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "irr_class",
    header: "IRR Class",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "drainage_class",
    header: "Drainage Class",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "farmland_class",
    header: "Farmland Class",
    cell: (info) => info.getValue(),
  },
];

const Soils = () => {
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();
  const isLoadingData = data === null;

  const averageSoilScore =
    data && data.length > 0
      ? data.reduce(
          (sum, item) =>
            sum + (item.nccpi_score || 0) * (item.percent_field || 0),
          0
        ) / data.reduce((total, item) => total + (item.percent_field || 0), 0)
      : 0;
  const scaledAverageScore = averageSoilScore * 100;

  const chartData = {
    labels: ["Average Soil Score", "Remaining"],
    datasets: [
      {
        data: [scaledAverageScore, 100 - scaledAverageScore],
        backgroundColor: ["#4caf50", "#e0e0e0"],
        borderWidth: 1,
        color: "#fff",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: "60%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  const onRowHover = (row: SoilData) => {
    if (SoilHighlightLayer && row.soil_code) {
      SoilHighlightLayer.renderer = createHighlightRenderer(
        row.soil_code,
        row.irr_class
      );
      SoilHighlightLayer.opacity = 0.7;
    }
  };

  const onRowLeave = () => {
    if (SoilHighlightLayer && data) {
      SoilHighlightLayer.renderer = undefined as unknown as __esri.Renderer;
    }
  };

  useEffect(() => {
    setGroup({
      groupName: "SoilsGroupLayer",
      layers: ["SoilsLayer"],
    });
  }, []);
  // Unmount
  useEffect(
    () => () => setGroup({ groupName: "SoilsGroupLayer", layers: null }),
    []
  );

  return (
    <div className="min-w-[436px] max-w-[450px]">
      <div className="flex flex-row items-center">
        <h2 className="text-[32px]  mr-1">Soils</h2>
        <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
        <Tooltip
          className="max-w-[400px]"
          anchorSelect=".question-mark"
          place="bottom"
          offset={10}
        >
          Soil map units are the basic geographic unit of the Soil Survey
          Geographic Database (SSURGO). The SSURGO dataset is a compilation of
          soils information collected over the last century by the Natural
          Resources Conservation Service (NRCS)
        </Tooltip>
      </div>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : isLoadingData ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          Loading...
        </p>
      ) : data && data.length > 0 ? (
        <>
          <div className="my-4">
            <strong className="text-2xl">Average Soil Score: </strong>
          </div>
          <div className="flex items-centerh-40 my-4">
            <div className="h-[100px]">
              <Doughnut data={chartData} options={chartOptions} />
            </div>
            <div className="flex items-center ml-6">
              <div className="flex items-baseline">
                <span className="text-4xl font-bold">
                  {scaledAverageScore.toFixed(2)}
                </span>
                <span className="text-sm ml-1">/ 100</span>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoadingData}
            onRowHover={(row) => onRowHover(row)}
            onRowLeave={onRowLeave}
          />
        </>
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
            Select a point to view data
          </p>
        </>
      )}
    </div>
  );
};

export default Soils;
