import axiosBalancer from "../api";
import { AircraftData, AircraftListRequestParams } from "../types/aircraft";

export type AircraftResponse =
  | AircraftData
  | { error: boolean; message: string };

export const getAircraftData = async (
  state: string,
  objectId: number
): Promise<AircraftResponse> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/aircraft",
      method: "get",
      params: { state, objectid: objectId },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};

export type AircraftListResponse =
  | AircraftData[]
  | { error: boolean; message: string };

export const getAircraftList = async (
  params: AircraftListRequestParams
): Promise<AircraftListResponse> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/aircraft/list",
      method: "post",
      data: params,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};
