import React, { FC, useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import Toggle from "../../atoms/Toggle";
import {
  getSelectedOption,
  mortgageForm,
  mortgageInformation,
} from "../../../utils/mortgage";
import DynamicInput from "../DynamicInput";
import clsx from "clsx";
import Button from "../../atoms/Button";
import { InferType } from "yup";
import { mortgageFormSchema } from "../../../schemas/mortgage";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../molecules/Modal";
import { IAttributes } from "../../../contexts/BoundsUI.context";
import { getMortgageData, postMortgageData } from "../../../services/mortgage";
import { IMortgage, IMortgageResponse } from "../../../types/mortgage";
import { toast, ToastOptions } from "react-toastify";
import { ToastSettings } from "../../../utils/toast";
import DatePicker from "../../molecules/DatePicker";
import { formatDate } from "../../../utils/date";
import usePrevious from "../../../hooks/usePrevious";
import DetailCard from "../../atoms/DetailCard";

interface IMortgageForm {
  title: string;
  attributes: IAttributes;
  onCancel: () => void;
  isAlertOpen: boolean;
  setIsAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MortgageForm: FC<IMortgageForm> = ({
  title,
  onCancel,
  isAlertOpen,
  setIsAlertOpen,
  attributes,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [confirmReleaseModal, setConfirmReleaseModal] = useState(false);

  // A Mortgage Release is distinct from a Mortgage itself; hence, the toggle is intentionally deactivated.
  const [isToggleDisabled, setIsToggleDisabled] = useState(false);
  const [updatingMortgage, setUpdatingMortgage] = useState(false);
  const [mortgageDetails, setMortgageDetails] = useState<object>();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(mortgageFormSchema),
    defaultValues: { isRelease: false },
    mode: "onChange",
  });
  const isMortgageRelease = watch("isRelease");
  const [mortgageData, setMortgageData] = useState<IMortgageResponse | null>(
    null
  );

  const handleCancel = () => {
    setIsAlertOpen(false);
    reset();
    onCancel();
  };

  const onSubmit = async (values: InferType<typeof mortgageFormSchema>) => {
    setUpdatingMortgage(true);
    const mortgageValues: IMortgage = {
      state: attributes.site_state as string,
      is_release: Boolean(isMortgageRelease),
      parcel_lid: attributes.PARCEL_LID,
      grantor_1: String(values.grantor_1),
      grantor_2: String(values.grantor_2),
      grantee_1: String(values.grantee_1),
      grantee_2: String(values.grantee_2),
      grantee_3: String(values.grantee_3),
      data_type: String(values.dataType),
      date: new Date(values.date).toISOString(),
      region: String(values.region),
      county: String(values.county),
      parcel_description: String(values.parcelDescription),
      quarter_description: String(values.quarterDescription),
      section: String(values.section),
      township: String(values.township),
      range: String(values.range),
      mortgage_amount: values.mortgageAmount
        ? String(values.mortgageAmount)
        : null,
      page: values.pageNumber ? String(values.pageNumber) : null,
      mortgage_city: String(values.mortgageCity),
      owner_address_1: String(values.ownerAddressLine1),
      owner_address_2: values.ownerAddressLine2,
      owner_zip: String(values.ownerZip),
      notes: values.notes,
    };

    postMortgageData(mortgageValues)
      .then((response) => {
        setUpdatingMortgage(false);
        if (response.error) {
          toast.error(response.message, ToastSettings as ToastOptions);
        } else {
          toast.success("Data sent.", ToastSettings as ToastOptions);
        }
        handleCancel();
      })
      .catch((error) => {
        console.error("Error updating mortgage data:", error);
        setUpdatingMortgage(false);
      });
  };

  const handleMortgageData = () => {
    getMortgageData(
      attributes.site_state as string,
      attributes.PARCEL_LID
    ).then((data) => {
      if (typeof data !== "string") {
        setMortgageData(data);
      }
      setIsLoading(false);
    });
  };

  const undoneRelease = () => {
    setConfirmReleaseModal(false);
    setValue("isRelease", false);
  };

  const confirmReleaseChange = () => {
    const releaseDate = getValues("date");
    if (isMortgageRelease && releaseDate) {
      setConfirmReleaseModal(false);
    } else {
      setError("date", { message: "Enter a valid date" });
    }
  };

  /* getMortgageData */
  useEffect(() => {
    if (attributes) {
      handleMortgageData();
    }
  }, [attributes]);

  useEffect(() => {
    if (mortgageData) {
      reset({
        grantor_1: mortgageData.grantor_name,
        grantor_2: mortgageData.grantor_name2,
        grantee_1: mortgageData.grantee_full_name,
        grantee_2: mortgageData.grantee_full_name2,
        grantee_3: mortgageData.grantee_full_name3,

        dataType: mortgageData.data_type,
        date: mortgageData.date ? String(mortgageData.date) : "",
        region: mortgageData.region,
        parcelDescription: mortgageData.l_or_parcel_description,
        quarterDescription: mortgageData.quarter_description,
        section: mortgageData.section,
        township: mortgageData.township,
        range: mortgageData.range,
        mortgageAmount: mortgageData.mortgage_amount
          ? parseInt(mortgageData.mortgage_amount)
          : undefined,
        pageNumber: mortgageData.page ? mortgageData.page : undefined,
        ownerAddressLine1: mortgageData.owner_address_line_1,
        ownerAddressLine2: mortgageData.owner_address_line_2,
        ownerZip: mortgageData.owner_zip,
        notes: mortgageData.notes,
        mortgageCity: mortgageData.city,
        county: mortgageData.county,
      });
      const isMortgageRelease = Boolean(mortgageData.mortgagerelease_id);
      setValue("isRelease", isMortgageRelease);
      if (isMortgageRelease) {
        setIsToggleDisabled(true);
      }
      /* mortgage details */
      setMortgageDetails({
        createdBy: mortgageData.created_by
          ? `${mortgageData.created_by.first_name} ${mortgageData.created_by.last_name}`
          : "",
        createdOn: mortgageData.created_on
          ? formatDate(mortgageData.created_on)
          : "",
        updatedBy: mortgageData.updated_by
          ? `${mortgageData.updated_by.first_name} ${mortgageData.updated_by.last_name}`
          : "",
        updatedOn: mortgageData.updated_on
          ? formatDate(mortgageData.updated_on)
          : "",
      });
    } else {
      reset({
        county: attributes.COUNTY,
        section: attributes.SECTION,
        township: attributes.TOWNSHIP,
        range: attributes.RANGE,
        parcelDescription: attributes.Legal_Full,
        grantor_1: attributes.OWNER_NAME,
        ownerAddressLine1: attributes.mail_addr,
        mortgageCity: attributes.mail_city,
        ownerZip: String(attributes.mail_zip),
      });
    }
    return () => {
      reset();
    };
  }, [mortgageData, attributes]);

  useEffect(() => {
    if (
      Boolean(mortgageData?.mortgagerelease_id) !== isMortgageRelease &&
      isMortgageRelease
    ) {
      setConfirmReleaseModal(true);
    } else setConfirmReleaseModal(false);
  }, [isMortgageRelease, mortgageData]);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center">
          <p>Checking for existing Mortgage Data...</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="px-4">
          <div className="flex gap-4 my-5">
            {/* Mortgage <Toggle Control> Mortgage Release */}
            <Toggle
              label={`Mortgage`}
              name="isRelease"
              control={control}
              disabled={isToggleDisabled}
            />
            <label
              className={clsx(
                isMortgageRelease
                  ? "text-boundsYellow-50 font-bold"
                  : "text-white"
              )}
            >
              Release
            </label>
          </div>
          <div className=" py-6">
            <div className="grid grid-cols-2 gap-y-6 gap-x-4">
              {mortgageForm.map(({ component, column = 2, ...rest }, index) => (
                <div
                  key={`input_${index}`}
                  className={clsx(`col-span-${column}`)}
                >
                  <DynamicInput
                    component={component}
                    control={control}
                    {...rest}
                    errorMessage={(errors as any)[rest.name]?.message}
                    register={register}
                  />
                </div>
              ))}
              {mortgageInformation.map(({ label, column = 2, name }, index) => (
                <div
                  key={`input_${index}`}
                  className={clsx(`col-span-${column}`)}
                >
                  <DetailCard
                    label={label}
                    content={
                      mortgageDetails
                        ? mortgageDetails[
                            name as keyof typeof mortgageDetails
                          ] || ""
                        : ""
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between w-full my-5">
            <Button
              label={"Cancel"}
              variant="secondary"
              onClick={() => setIsAlertOpen(true)}
            />
            <Button label={"Save"} type="submit" isLoading={updatingMortgage} />
          </div>
        </form>
      )}
      {/* Confirm change mortgage type, */}
      <Modal
        isOpen={confirmReleaseModal}
        onClose={() => setConfirmReleaseModal(false)}
        width={500}
        overflow={"visible"}
      >
        <p className="text-xl font-bold mb-4">Confirm mortgage release date</p>
        <DatePicker
          label="Select Date"
          control={control as unknown as Control<FieldValues>}
          name={"date"}
          placeholder="MM/DD/YYYY"
          errorMessage={errors.date?.message}
        />
        <div className="flex justify-between w-full mt-5 gap-4">
          <Button
            label={"Cancel"}
            variant="secondary"
            className="w-full"
            onClick={undoneRelease}
          />
          <Button
            onClick={confirmReleaseChange}
            label={"Save"}
            className="w-full"
          />
        </div>
      </Modal>

      {/* Confirm scape */}
      <Modal
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        width={500}
      >
        <h1 className="font-bold text-boundsYellow-50 text-lg">
          Confirmation Required.
        </h1>
        <p>
          Are you sure you want to cancel? This action will delete all the
          information you&apos;ve entered in the form. This action cannot be
          undone.
        </p>
        <div className="flex justify-between w-full mt-5 gap-4">
          <Button
            label={"Yes, Cancel"}
            variant="secondary"
            className="w-full"
            onClick={handleCancel}
          />
          <Button
            onClick={() => setIsAlertOpen(false)}
            label={"No, Continue"}
            className="w-full"
          />
        </div>
      </Modal>
    </>
  );
};

export default MortgageForm;
