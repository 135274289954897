import React, { ReactElement } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import clsx from "clsx";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { CSSObjectWithLabel, GroupBase } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { IOption } from "../../../types/search";
import getStylesConfig from "./SelectStyles";
import "./Select.scss";
import CustomMenu from "./CustomMenu";

export interface CreatableSelectCustomProps
  extends CreatableProps<IOption, boolean, GroupBase<IOption>> {
  control: Control<any, any, any>;
  label: string;
  name: string;
  isSearch?: boolean;
  isSearching?: boolean;
  variant?: "primary" | "secondary";
  allowCreateOption?: boolean;
  rules?:
    | Omit<
        RegisterOptions<any, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  MultiValue?: (props: any) => JSX.Element;
}

const selectStyles = {
  ...getStylesConfig("primary"),
  multiValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    background: "#6d5a68",
    paddingLeft: "2px",
  }),
  multiValueRemove: (styles: CSSObjectWithLabel) => ({
    ...styles,
    display: "inherit",
  }),
};

const CreatableSelectComponent = React.forwardRef<
  CreatableSelect,
  CreatableSelectCustomProps
>((props, ref): ReactElement => {
  const {
    control,
    label,
    name,
    variant = "primary",
    MultiValue = undefined,
    isSearch,
    isSearching,
    rules,
    onInputChange,
    isLoading = false,
    allowCreateOption = true,
    ...otherProps
  } = props;

  return (
    <div
      className={clsx(
        "min-w-32 xl:min-w-40",
        "rounded-t-md py-2 px-4 border-b relative",
        variant === "primary" && "bg-boundsPurple-150 border-boundsYellow-50",
        variant === "secondary" && "bg-boundsGray-100 border-white",
        props.isDisabled && "opacity-50",
        props.className
      )}
    >
      {isSearching && (
        <FontAwesomeIcon
          className="fa-ellipsis absolute bottom-30 right-2 fa-beat"
          icon={faEllipsisH}
        />
      )}
      <label
        htmlFor={name}
        className={clsx("text-xs", variant === "secondary" && "text-black")}
      >
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <CreatableSelect
            className="select-container"
            styles={selectStyles}
            components={{
              ...(MultiValue && { MultiValue }),
              Menu: (props) => <CustomMenu {...props} isLoading={isLoading} />,
            }}
            isMulti={true}
            hideSelectedOptions={false}
            formatCreateLabel={(userInput) => {
              const trimmed = userInput?.trim();
              if (!trimmed) return null;

              if (isSearch && !allowCreateOption) {
                return `Search for ${trimmed}`;
              }

              return `Create ${trimmed}`;
            }}
            {...field}
            {...otherProps}
            ref={field.ref}
            onChange={(value) => field.onChange(value)}
            onInputChange={onInputChange}
            isLoading={isLoading}
            allowCreateWhileLoading={true}
            isValidNewOption={() => allowCreateOption}
          />
        )}
      />
    </div>
  );
});

CreatableSelectComponent.displayName = "CreatableSelectComponent";

export default CreatableSelectComponent;
