import { configureStore } from "@reduxjs/toolkit";
import popupReducer, { syncSelectedParcelLId } from "./slides/popupSlice";

const store = configureStore({
  reducer: {
    popup: popupReducer,
  },
});

window.addEventListener("storage", (event) => {
  if (event.key === "popupState" && event.newValue) {
    const newState = JSON.parse(event.newValue);
    store.dispatch(syncSelectedParcelLId(newState.selectedParcelLId));
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
