import React, { useState, useEffect } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import Select, { CSSObjectWithLabel, StylesConfig } from "react-select";

import getStylesConfig from "../../../atoms/Select/SelectStyles";
import { ISearchFormValues, SearchFieldIdName } from "../../../../types/search";
import SearchSelect from "./SearchSelect";
import {
  SEARCH_PARCEL_ID_CATEGORY_OPTIONS,
  SEARCH_PARCEL_ID_DICT,
} from "./SearchInputs";
import { useAuth } from "../../../../hooks/useAuth";

const SearchParcelSelect = ({
  control,
  setValue,
  isSubmitting,
}: {
  control: Control<ISearchFormValues>;
  setValue: UseFormSetValue<ISearchFormValues>;
  isSubmitting: boolean;
}) => {
  const defaultCategory = SEARCH_PARCEL_ID_CATEGORY_OPTIONS[1];
  const [idCategory, setIdCategory] = useState(defaultCategory);
  const [searchInput, setSearchInput] = useState(
    SEARCH_PARCEL_ID_DICT[defaultCategory.value]
  );
  const { isMarketIntelAdmin, isMarketIntelOps } = useAuth();
  isMarketIntelAdmin() || isMarketIntelOps()
    ? ""
    : SEARCH_PARCEL_ID_CATEGORY_OPTIONS.splice(3, 1);

  const resetIdFields = () => {
    setValue(SearchFieldIdName.Parcel, []);
    setValue(SearchFieldIdName.UniqueTax, []);
    setValue(SearchFieldIdName.AlternateTax, []);
  };

  const selectStyles = {
    ...getStylesConfig("primary"),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: "initial",
      cursor: "pointer",
    }),
  };

  useEffect(() => {
    resetIdFields();
    setSearchInput(SEARCH_PARCEL_ID_DICT[idCategory.value]);
  }, [idCategory]);

  return (
    <>
      <div className="">
        <div className="min-w-32 xl:min-w-40 rounded-t-md py-2 px-4 border-b relative bg-boundsPurple-150 border-boundsYellow-50 mb-1">
          <Select
            name="searchIdCategory"
            className="select-container"
            styles={selectStyles as StylesConfig}
            options={SEARCH_PARCEL_ID_CATEGORY_OPTIONS}
            defaultValue={defaultCategory}
            onChange={(category) =>
              setIdCategory(
                category as { label: string; value: SearchFieldIdName }
              )
            }
            isSearchable={false}
          />
        </div>

        {idCategory?.value && (
          <SearchSelect
            searchInput={searchInput}
            control={control}
            isSubmitting={isSubmitting}
            setValue={setValue}
          />
        )}
      </div>
    </>
  );
};

export default SearchParcelSelect;
