import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { IMapSearchResult } from "../../../../types/search";

export const getSearchColumns = (
  isMarketIntelAdmin: () => boolean,
  isMarketIntelOps: () => boolean
) => {
  const columns: ColumnDef<IMapSearchResult>[] = [
    {
      accessorKey: "owner_name",
      header: "Owner Name",
      enableColumnFilter: true,
      enableSorting: true,
      size: 135,
    },
    {
      accessorKey: "description",
      header: "Description",
      enableColumnFilter: false,
      enableSorting: false,
      size: 120,
    },
    {
      accessorKey: "county",
      header: "County",
      enableColumnFilter: true,
      enableSorting: true,
      size: 120,
    },
    {
      accessorKey: "mortgage",
      header: "Mortgage",
      enableColumnFilter: false,
      enableSorting: true,
      size: 130,
      minSize: 130,
      cell: ({ row }) => {
        const mortgage = row.original.mortgage;
        return (
          <Link
            to={`/dashboard/mortgage?state=${row.original.site_state}&parcel_lid=${row.original.parcel_lid}`}
            className={clsx(
              "font-medium rounded m-auto px-3 text-sm font-roboto py-2 flex justify-center",
              mortgage
                ? "!bg-boundsYellow-50 text-black "
                : "!bg-boundsGray-500 text-white"
            )}
            onClick={(e) => {
              if (!mortgage) {
                e.preventDefault();
              }
            }}
          >
            {mortgage ? "View" : "None"}
          </Link>
        );
      },
    },
    {
      accessorKey: "ucc",
      header: "UCC",
      enableColumnFilter: false,
      enableSorting: true,
      size: 130,
      minSize: 130,
      cell: ({ row }) => {
        const ucc =
          row.original.ucc !== null &&
          row.original.ucc !== "" &&
          row.original.ucc !== "0";
        return (
          <Link
            to={`/dashboard/ucc?state=${row.original.site_state}&parcel_lid=${row.original.parcel_lid}`}
            className={clsx(
              "font-medium rounded m-auto px-3 text-sm font-roboto py-2 flex justify-center",
              ucc
                ? "!bg-boundsYellow-50 text-black "
                : "!bg-boundsGray-500 text-white"
            )}
            onClick={(e) => {
              if (!ucc) {
                e.preventDefault();
              }
            }}
          >
            {ucc ? "View" : "None"}
          </Link>
        );
      },
    },
  ];

  if (isMarketIntelAdmin() || isMarketIntelOps()) {
    columns.push({
      accessorKey: "parcel_lid",
      header: "Parcel id",
      enableColumnFilter: false,
      enableSorting: false,
      size: 125,
      minSize: 125,
      cell: ({ getValue }: { getValue: () => any }) => (
        <>{getValue() == null ? "No Data" : getValue()}</>
      ),
    });
  }

  return columns;
};
