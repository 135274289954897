import { cloneDeep } from "lodash";

export enum SORT_ORDER {
  ASC = "asc",
  DESC = "desc",
}

export interface ISorting {
  sort_by: string;
  sort_order: SORT_ORDER;
}

export interface IFiltering {
  filter_column: string;
  filter_value1: string;
  filter_value2?: string;
}

export interface IPagination {
  page: number;
  page_size: number;
  include_count: boolean;
}

export interface IFetchParams {
  state: string;
  pagination: IPagination;
  filtering?: IFiltering[];
  sorting?: ISorting[];
}

const defaultFetchParams: IFetchParams = {
  state: "KS",
  pagination: {
    page: 1,
    page_size: 100,
    include_count: false,
  },
};

export const getDefaultFetchParams = (): IFetchParams => {
  return cloneDeep(defaultFetchParams);
};

export interface IFetchResponse<T> {
  data: T[];
  total_count?: number | null;
}

export const EmptyFetchResponse: Readonly<IFetchResponse<never>> = Object.freeze({
  data: [],
  total_count: 0,
});
