import * as yup from "yup";

export const loginSchema = yup.object().shape({
  username: yup.string().required("Username is required."),
  password: yup.string().required("Password is required"),
});

export const mfaSetupSchema = yup.object().shape({
  totp_code: yup.string().required("MFA code is required."),
});
