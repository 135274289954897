import axiosBalancer from "../api";
import { EmptyFetchResponse, IFetchResponse } from "../types/table";
import { IUccData, IUccFetchParams } from "../types/ucc";

export const getUcc = async (params: IUccFetchParams, signal: AbortSignal): Promise<IFetchResponse<IUccData>> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/ucc",
      method: "post",
      data: params,
      signal: signal,
    });
    return {
      data: response.data.data,
      total_count: response.data.total_count,
    } as IFetchResponse<IUccData>;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return EmptyFetchResponse;
  }
};

export const getUccFile = async (filepath: string): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/ucc/file",
      method: "get",
      params: { filepath },
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.error("Error getting file");
      return null;
    }
  }
};
