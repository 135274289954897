import { IOption } from "../types/search";
import { counties } from "./counties";

export type ComponentType =
  | "Select"
  | "Input"
  | "DatePicker"
  | "SearchSelect"
  | "MultiFilterSelect";
interface IForm {
  label: string;
  name: string;
  component: ComponentType;
  placeholder?: string;
  column?: number;
  type?: string;
  options?: IOption[];
  isMulti?: boolean;
  queryFieldName?: string;
  defaultValue?: IOption;
  readOnly?: boolean;
}

export const mortgageForm: IForm[] = [
  { label: "Grantor Name 1", name: "grantor_1", component: "Input" },
  { label: "Grantor Name 2", name: "grantor_2", component: "Input" },
  { label: "Grantee Name 1", name: "grantee_1", component: "Input" },
  {
    label: "Grantee Name 2",
    name: "grantee_2",
    component: "Input",
  },
  {
    label: "Grantee Name 3",
    name: "grantee_3",
    component: "Input",
  },
  { label: "Data Type", name: "dataType", component: "Input", column: 1 },
  { label: "Date", name: "date", component: "DatePicker", column: 1 },
  { label: "Region", name: "region", component: "Input", column: 1 },
  {
    label: "County",
    name: "county",
    component: "Input",
    column: 1,
    readOnly: true,
  },

  {
    label: "Parcel Description",
    name: "parcelDescription",
    component: "Input",
  },
  {
    label: "Quarter Description",
    name: "quarterDescription",
    component: "Input",
  },
  { label: "Section", name: "section", component: "Input" },
  { label: "Township", name: "township", component: "Input", column: 1 },
  { label: "Range", name: "range", component: "Input", column: 1 },
  {
    label: "Mortgage Amount",
    name: "mortgageAmount",
    component: "Input",
    type: "number",
    column: 1,
  },
  {
    label: "Page Number",
    name: "pageNumber",
    component: "Input",
    column: 1,
  },
  {
    label: "Owner Address Line1",
    name: "ownerAddressLine1",
    component: "Input",
  },
  {
    label: "Owner Address Line2",
    name: "ownerAddressLine2",
    component: "Input",
  },
  { label: "Owner Zip", name: "ownerZip", component: "Input", column: 1 },
  {
    label: "Mortgage City",
    name: "mortgageCity",
    component: "Input",
    column: 1,
  },
  { label: "Notes", name: "notes", component: "Input" },
];

export const getSelectedOption = (valueFromBackend: string): IOption => {
  const selectedCounty = counties.find(
    (county) => county.value === valueFromBackend
  );
  return selectedCounty
    ? { label: selectedCounty.County, value: selectedCounty.value }
    : ({} as IOption);
};

export const mortgageInformation = [
  {
    label: "Created by",
    name: "createdBy",
    column: 1,
  },
  {
    label: "Created on",
    name: "createdOn",
    column: 1,
  },
  {
    label: "Updated by",
    name: "updatedBy",
    column: 1,
  },
  {
    label: "Updated on",
    name: "updatedOn",
    column: 1,
  },
];
