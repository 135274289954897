import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  IMoDebtorData,
  IMoSecPartyData,
  IUccData,
  IUccDebtorData,
  IUccKSData,
  IUccMOData,
  IUccSecPartyData,
} from "../../../../types/ucc";
import { formatDate } from "../../../../utils/date";
import ExpandableText from "../../../molecules/ExpandableText/ExpandableText";
import { getUccFile } from "../../../../services/ucc";

const openUccFile = (event: any, filepath: string) => {
  const button = event.target as HTMLButtonElement;
  button.disabled = true;

  const extensionToMime: { [key: string]: string } = {
    pdf: "application/pdf",
    tif: "image/tiff",
    tiff: "image/tiff",
  };

  const extension = filepath.split(".").pop()?.toLowerCase() || "";
  const mimeType = extensionToMime[extension] || "application/octet-stream";

  getUccFile(filepath)
    .then((response: any) => {
      if (response) {
        const blob = new Blob([response], { type: mimeType });
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl);
        button.disabled = false;
      }
    })
    .finally(() => {
      button.disabled = false;
    });
};

export const ksUccColumns: ColumnDef<IUccKSData>[] = [
  {
    accessorKey: "u1_code",
    header: "UCC1 Code",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => {
      const data = row.original;
      return data.u1_code ?? "No Data";
    },
  },
  {
    accessorKey: "u3_code",
    header: "UCC3 Code",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => {
      const data = row.original;
      return data.u3_code ?? "No Data";
    },
  },
  {
    accessorKey: "entryts",
    header: "Date",
    enableColumnFilter: true,
    enableSorting: true,
    cell: ({ row }) => {
      const date = row.original.entryts;
      return date ? formatDate(date) : "No Data";
    },
  },
  {
    accessorKey: "filepath",
    header: "File",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ getValue }) => {
      const value = getValue() as string;
      if (value) {
        return (
          <div className="flex items-center justify-center">
            <button
              className="bg-boundsYellow-50 text-black py-1 px-2 rounded-lg min-w-8"
              onClick={(event) => openUccFile(event, value)}
            >
              View
            </button>
          </div>
        );
      }
      return "No Data";
    },
  },
  {
    accessorKey: "us_json",
    header: "Sec Party",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ row }) => {
      const jsonString = row.original.us_json;
      if (!jsonString) return "No Data";
      try {
        const parties = JSON.parse(jsonString) as IUccSecPartyData[];
        return (
          <div className="flex flex-col gap-2">
            {parties.map((item, index) => (
              <div key={`ks-sp-${index}`}>
                {[
                  item.us_prefix,
                  item.us_first_name,
                  item.us_middle_name,
                  item.us_last_name,
                ]
                  .filter(Boolean)
                  .join(" ")}
                {item.us_suffix && ", " + item.us_suffix}
                <br />
                {item.us_addr1 || "No Data"}
                <br />
                {item.us_city}, {item.us_state} {item.us_zip}
              </div>
            ))}
          </div>
        );
      } catch (err) {
        console.error("Invalid JSON in Sec Party column", err);
        return "Invalid data";
      }
    },
  },
  {
    accessorKey: "uc_json",
    header: "Collateral",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ getValue }) => {
      const value = getValue() as string;
      if (!value) return "";
      try {
        const json = JSON.parse(value);
        const fullText = json
          .map((item: any) => item.uc_text_notes || "")
          .filter(Boolean)
          .join("\n\n");
        return <ExpandableText text={fullText} maxLength={400} />;
      } catch (err) {
        console.error("Invalid JSON in Collateral column", err);
        return "Invalid data";
      }
    },
  },
  {
    accessorKey: "ub_json",
    header: "Debtor",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ row }) => {
      const jsonString = row.original.ub_json;
      if (!jsonString) return "No Data";
      try {
        const parties = JSON.parse(jsonString) as IUccDebtorData[];
        const fullText = parties
          .map((item) =>
            [
              [
                item.ub_prefix,
                item.ub_first_name,
                item.ub_middle_name,
                item.ub_last_name,
              ]
                .filter(Boolean)
                .join(" ") + (item.ub_suffix ? `, ${item.ub_suffix}` : ""),
              item.ub_addr1,
              item.ub_addr2,
              item.ub_addr3,
              item.ub_addr4,
              `${item.ub_city}, ${item.ub_state} ${item.ub_zip}`,
              item.ub_country,
            ]
              .filter(Boolean)
              .join("\n")
          )
          .join("\n\n");
        return <ExpandableText text={fullText} maxLength={350} />;
      } catch (err) {
        console.error("Invalid JSON in Debtor column", err);
        return "Invalid data";
      }
    },
  },
];

export const moUccColumns: ColumnDef<IUccMOData>[] = [
  {
    accessorKey: "originalfilenumber",
    header: "Original Filing",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => {
      const data = row.original;
      return data.originalfilenumber ?? "No Data";
    },
  },
  {
    accessorKey: "filenumber",
    header: "File Number",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => {
      const data = row.original;
      return data.filenumber ?? "No Data";
    },
  },
  {
    accessorKey: "type",
    header: "Type",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ row }) => {
      const data = row.original;
      return data.type ?? "No Data";
    },
  },
  {
    accessorKey: "filedatetime",
    header: "Date",
    enableColumnFilter: true,
    enableSorting: true,
    cell: ({ row }) => {
      const date = row.original.filedatetime;
      if (!date) return "No Data";
      return formatDate(date.replace(" ", "T"));
    },
  },
  {
    accessorKey: "filepath",
    header: "File",
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ getValue }) => {
      const value = getValue() as string;
      if (value) {
        return (
          <div className="flex items-center justify-center">
            <button
              className="bg-boundsYellow-50 text-black py-1 px-2 rounded-lg min-w-8"
              onClick={(event) => openUccFile(event, value)}
            >
              View
            </button>
          </div>
        );
      }
      return "No Data";
    },
  },
  {
    accessorKey: "us_json",
    header: "Sec Party",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ row }) => {
      const jsonString = row.original.us_json;
      if (!jsonString) return "No Data";
      try {
        const parties = JSON.parse(jsonString) as IMoSecPartyData[];
        return (
          <div className="flex flex-col gap-2">
            {parties.map((item, index) => (
              <div key={`mo-sp-${index}`}>
                {item.OrganizationName || "No Data"}
                <br />
                {item.addr1 || "No Data"}
                <br />
                {item.City}, {item.State} {item.PostalCode}
                <br />
                {item.Country}
              </div>
            ))}
          </div>
        );
      } catch (err) {
        console.error("Invalid JSON in Sec Party column", err);
        return "Invalid data";
      }
    },
  },
  {
    accessorKey: "ub_json",
    header: "Debtor",
    enableColumnFilter: true,
    enableSorting: false,
    cell: ({ row }) => {
      const jsonString = row.original.ub_json;
      if (!jsonString) return "No Data";
      try {
        const parties = JSON.parse(jsonString) as IMoDebtorData[];
        const fullText = parties
          .map((item) =>
            [
              (item.OrganizationalType === "Individual"
                ? [item.FirstName, item.LastName].filter(Boolean).join(" ")
                : item.OrganizationName) ?? "No Data",
              item.addr1 || "No Data",
              `${item.City}, ${item.State} ${item.PostalCode}`,
              item.Country,
            ]
              .filter(Boolean)
              .join("\n")
          )
          .join("\n\n");
        return <ExpandableText text={fullText} maxLength={350} />;
      } catch (err) {
        console.error("Invalid JSON in Debtor column", err);
        return "Invalid data";
      }
    },
  },
];

export function getUccColumns(state: string): ColumnDef<IUccData>[] {
  switch (state) {
    case "MO":
      return moUccColumns as ColumnDef<IUccData>[];
    case "KS":
      return ksUccColumns as ColumnDef<IUccData>[];
    default:
      // Return an empty array. This is still assignable to the union type.
      return [];
  }
}
