import React, { useState, useEffect } from "react";
import QRCode from "qrcode";

interface QRCodeProps {
  url: string;
  label?: string;
}

const QRCodeGenerator: React.FC<QRCodeProps> = ({ url, label }) => {
  const [qrCode, setQrCode] = useState<string>("");

  useEffect(() => {
    if (url) {
      QRCode.toDataURL(url)
        .then((dataUrl) => setQrCode(dataUrl))
        .catch((err) => console.error("Error generating QR Code", err));
    }
  }, [url]);

  return (
    <div className="flex flex-col justify-center items-center">
      <h3 className="text-boundsYellow-50 text-center text-[14px]">{label}</h3>
      {qrCode ? (
        <img src={qrCode} alt="Generated QR Code" className="w-56 my-1" />
      ) : (
        <p>Generating QR Code...</p>
      )}
    </div>
  );
};

export default QRCodeGenerator;
