import axiosBalancer from "../api";
import { ElevationData, ITax, SoilData } from "../types/insights";

export const getCropDataByParcel = async (state_code: string, parcel_lid: string): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/cdl/by-parcel",
      method: "get",
      params: { state: state_code, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getYieldData = async (
  county: string,
  state_code: string
): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/crop-yield",
      method: "get",
      params: { county, state: state_code },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getPropertyTaxByAssessment = async (
  state_code: string,
  assessment_lid: string
): Promise<ITax[]> => {
  const response = await axiosBalancer.request({
    url: "/api/tax/by-assessment",
    method: "get",
    params: { state: state_code, primary_assessment_lid: assessment_lid },
  });
  return response.data;
};

export const getElevationDataByParcelId = async (
  state_code: string,
  parcel_lid: string
): Promise<ElevationData | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/elevation-insights",
      method: "get",
      params: { state: state_code, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getSoilData = async (state_code: string, parcel_lid: string): Promise<SoilData[]> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/soil-insights",
      method: "get",
      params: { state: state_code, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return [];
  }
};
