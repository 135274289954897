import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import Providers from "./providers";
// Pages
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ReactModal from "react-modal";
import MFAPage from "./pages/MFA";
import ResetMFA from "./pages/ResetMFA";

const App = () => {
  const isProduction = process.env.NODE_ENV === "production";
  const version = packageInfo.version;
  document.body.classList.add("bg-boundsPurple-400");
  ReactModal.setAppElement("#root");

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={"."}
    >
      <Router>
        <Providers>
          <Routes>
            {/* Public Routes */}
            <Route element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-account" element={<ResetPassword />} />
              <Route path="/mfa-setup" element={<MFAPage />} />
              <Route path="/reset-mfa" element={<ResetMFA />} />
            </Route>
            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard/:tab?" element={<Dashboard />} />
            </Route>
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Providers>
      </Router>
    </CacheBuster>
  );
};

export default App;
