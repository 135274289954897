import React, { useMemo, useState } from "react";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { getMortgageHolders } from "../../../../services/mortgage";
import { IMortgageHolder } from "../../../../types/financialOverview";
import { IMortgageHoldersFetchParams } from "../../../../types/mortgage";
import {
  EmptyFetchResponse,
  getDefaultFetchParams,
  IFetchResponse,
} from "../../../../types/table";
import { formatDate } from "../../../../utils/date";
import { capitalizeEachWord, formatMoney } from "../../../../utils/string";
import Title from "../../../atoms/Title";
import InfiniteScrollTable from "../../../molecules/InfiniteScrollTable";

const MortgageHolder = ({ state }: { state: string }) => {
  const fetchParams = useMemo(
    () => ({
      ...getDefaultFetchParams(),
      state,
    }),
    [state]
  );

  const dispatch = useDispatch();

  const handleClick = (parcel_lid: string) => {
    dispatch(setSelectedParcelLId(parcel_lid));
    window.open("/", "_blank", "noopener,noreferrer");
  };

  const loadMortgageHolders = async (
    params: IMortgageHoldersFetchParams,
    signal: AbortSignal
  ): Promise<IFetchResponse<IMortgageHolder>> => {
    try {
      return await getMortgageHolders(params, signal);
    } catch (error: unknown) {
      if (error instanceof Error && error?.name != "AbortError") {
        console.error("Error fetching UCC data", error);
      }
    }
    return EmptyFetchResponse;
  };

  const columns = useMemo<ColumnDef<IMortgageHolder>[]>(
    () => [
      {
        accessorKey: "issue",
        sortField: "issue",
        header: "Map",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const value = getValue() as string;
          const parcel_lid = row.original.parcel_lid;
          if (!value && parcel_lid) {
            return (
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => handleClick(parcel_lid)}
              >
                <FontAwesomeIcon className="question-mark" icon={faMap} />
              </div>
            );
          }
          return <span className="text-black">N/A</span>;
        },
      },
      {
        accessorKey: "type",
        sortField: "type",
        header: "Type",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "date",
        header: "Date",
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return value ? formatDate(value) : "No Data";
        },
      },
      {
        accessorKey: "loan_institution",
        sortField: "loan_institution",
        header: "From",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "borrower",
        sortField: "borrower",
        header: "To",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return value && value.includes("No data")
            ? capitalizeEachWord(value)
            : value;
        },
      },
      {
        accessorKey: "county",
        sortField: "county",
        header: "County",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "mortgage_amount",
        sortField: "mortgage_amount",
        header: "Mortgage",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return value ? formatMoney(value) : "No Data";
        },
      },
    ],
    []
  );

  return (
    <div className="mt-4 border-4 border-boundsPurple-500 bg-boundsPurple-600 py-6 px-10 rounded-[30px]">
      <Title label="Mortgage Holder" />
      <InfiniteScrollTable
        className="my-4 min-h-80 h-[calc(100vh-550px)]"
        columns={columns}
        fetchData={loadMortgageHolders}
        fetchParams={fetchParams}
      />
    </div>
  );
};

export default MortgageHolder;
