import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetMFA } from "../../services/auth";
import { ToastOptions, toast } from "react-toastify";
import { ToastSettings } from "../../utils/toast";

const ResetMFA = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleResetMFA = async (token: string): Promise<boolean> => {
    try {
      const response = await resetMFA(token);
      if (!response) {
        toast.error(
          "Invalid or expired MFA reset token.",
          ToastSettings as ToastOptions
        );
        return false;
      }

      toast.success(
        response as unknown as string,
        ToastSettings as ToastOptions
      );
      navigate("/login");
      return true;
    } catch (error) {
      toast.error(
        "An unexpected error occurred while resetting MFA.",
        ToastSettings as ToastOptions
      );
      return false;
    }
  };

  useEffect(() => {
    if (token) {
      handleResetMFA(token);
    }
    setLoading(false);
  }, [token]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <p>Reset MFA process for token: {token}</p>
      )}
    </div>
  );
};

export default ResetMFA;
