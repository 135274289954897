import { createContext } from "react";
import { IAuthStatus, IMFAStatus } from "../types/auth.types";

interface AuthContextType {
  userId: number;
  groups: string[];
  isAuthenticated: boolean;
  isMarketIntelAdmin: () => boolean;
  isMarketIntelOps: () => boolean;
  isLoading: boolean;
  login: (username: string, password: string) => Promise<IMFAStatus | null>;
  logout: () => Promise<void>;
  checkPasswordResetToken: (email: string, token: string) => Promise<boolean>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (
    email: string,
    token: string,
    password: string
  ) => Promise<boolean>;
  isMFAActive: boolean;
  setIsMFAActive: React.Dispatch<React.SetStateAction<boolean>>;
  enableMFA: () => Promise<string | null>;
  validateMFA: (totpCode: string) => Promise<IAuthStatus | null>;
  resetMFA: () => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);
