import axiosBalancer from "../api";
import { IMortgageHolder } from "../types/financialOverview";
import { IMortgage, IMortgageHoldersFetchParams, IRateAnalysis, IRateAnalysisFetchParams } from "../types/mortgage";
import { EmptyFetchResponse, IFetchResponse } from "../types/table";

export const getMortgageData = async (state: string, parcel_lid: string): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage",
      method: "get",
      params: { state, parcel_lid },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};

export const postMortgageData = async (mortgageData: IMortgage): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage",
      method: "post",
      data: mortgageData,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      return {
        error: true,
        message: error.response.data || "Invalid arguments",
      };
    } else if (error.response?.status === 401) {
      return {
        error: true,
        message: "Unauthorized access",
      };
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};

export const getMortgageHolders = async (params: IMortgageHoldersFetchParams, signal: AbortSignal): Promise<IFetchResponse<IMortgageHolder>> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/holder",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return EmptyFetchResponse;
  }
};

export const getMortgageRateAnalysis = async (params: IRateAnalysisFetchParams, signal: AbortSignal): Promise<IFetchResponse<IRateAnalysis>> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/rate-analysis",
      method: "post",
      data: params,
      signal,
    });

    return response.data as IFetchResponse<IRateAnalysis>;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return EmptyFetchResponse;
  }
};
