import React from "react";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import CustomContent from "@arcgis/core/popup/content/CustomContent";
import { createRoot, Root } from "react-dom/client";
import { Provider } from "react-redux";
import CityPopup from "../components/features/CityPopup";
import store from "../redux/store";

const rootMap = new Map<Element, Root>();

export const cityDonutCustomContent = new CustomContent({
  outFields: ["minorciv_1"],
  creator: (feature) => {
    const cityName = feature?.graphic?.attributes?.minorciv_1;
    const container = document.createElement("div");
    const root = createRoot(container);
    rootMap.set(container, root);
    root.render(
      <Provider store={store}>
        <CityPopup city={cityName} />
      </Provider>
    );
    return container;
  },
});

const cityDonutPopupTemplate = {
  title: "Recent Mortgages",
  content: [cityDonutCustomContent],
  overwriteActions: true,
};

export const CityDonutLayer = new FeatureLayer({
  id: "CityDonutLayer",
  maxScale: 120828,
  minScale: 1317305,
  portalItem: {
    id: "8f16e0a35a4d48eeb85e5f6488e94e5c",
  },
  visible: true,
  popupTemplate: cityDonutPopupTemplate,
});
