import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer.js";
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import TileLayer from "@arcgis/core/layers/TileLayer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import { CityDonutLayer } from "./CityDonutLayer";
import PictureFillSymbol from "@arcgis/core/symbols/PictureFillSymbol";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import uccPattern from "../assets/images/ucc-pattern.png";
import mortgageReleasePattern from "../assets/images/mortgage-release-pattern.png";
const baseImageryScale = 333500;

const ElevationDEMLayer = new TileLayer({
  id: "ElevationDEMLayer",
  portalItem: {
    id: "71074ed09aab49328a85c860fc413379",
  },
  visible: false,
});

export const ElevationGroupLayer = new GroupLayer({
  title: "ElevationGroupLayer",
});
ElevationGroupLayer.visibilityMode = "independent";
ElevationGroupLayer.add(ElevationDEMLayer);

const Counties = new FeatureLayer({
  id: "Counties",
  portalItem: {
    id: "31260b24eb1642a29f03e2e118b03921",
  },
  popupEnabled: false,
  maxScale: 333500,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.county_nam",
      },
    },
  ],
});

const PLSSFirstDivision = new FeatureLayer({
  id: "PLSSFirstDivision",
  portalItem: {
    id: "30475e008003462786067e7284334b81",
  },
  popupEnabled: false,
  maxScale: 29122,
  minScale: 120828,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.Label",
      },
    },
  ],
});

const Civil_Divisions = new FeatureLayer({
  id: "Civil_Divisions",
  portalItem: {
    id: "5d65d7c559ca499ab625ba8a7867a974",
  },
  popupEnabled: false,
  maxScale: 120828,
  minScale: 333500,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.minorciv_1",
      },
    },
  ],
});

const ParcelLayerId = "d0c18cc45f334096a95ad7954d883b01";
const ParcelSymbol = new SimpleFillSymbol({
  color: "rgba(0,0,0,0)",
  outline: {
    color: "#F2C94C",
    width: 2,
  },
});

const ParcelRenderer = new SimpleRenderer({
  symbol: ParcelSymbol,
});

export const Parcellightboxlayer = new FeatureLayer({
  id: "Parcellightboxlayer",
  renderer: ParcelRenderer,
  portalItem: {
    id: ParcelLayerId,
  },
  popupEnabled: false,
  minScale: 29122,

  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.Owner_Name",
      },
    },
  ],
});

export const baseSymbol = new SimpleFillSymbol({
  color: "transparent",
  outline: {
    color: "transparent",
    width: 0,
  },
});

const uccSymbol = new PictureFillSymbol({
  url: uccPattern,
  width: 10,
  height: 10,
  outline: {
    color: [0, 0, 0, 0],
    width: 0,
  },
});

export const uccRenderer = new UniqueValueRenderer({
  field: "UCC",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: uccSymbol,
    },
  ],
  valueExpression: `
  When($feature.UCC > 1, 'not-null', 'null')
`,
});

const mortgageSymbol = new SimpleFillSymbol({
  color: [99, 0, 235, 0.5],
  style: "solid",
  outline: baseSymbol.outline,
});

export const mortgageRenderer = new UniqueValueRenderer({
  field: "Mortgage",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: mortgageSymbol,
    },
  ],
  valueExpression: `
    When(IsEmpty($feature.Mortgage) || $feature.Mortgage == 0, 'null', 'not-null')
  `,
});

const mortgageReleaseSymbol = new PictureFillSymbol({
  url: mortgageReleasePattern,
  width: 10,
  height: 10,
  outline: {
    color: [0, 0, 0, 0],
    width: 0,
  },
});

export const mortgageReleaseRenderer = new UniqueValueRenderer({
  field: "MortgageRelease",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: mortgageReleaseSymbol,
    },
  ],
  valueExpression: `
    When(IsEmpty($feature.MortgageRelease) || $feature.MortgageRelease == 0, 'null', 'not-null')
  `,
});

export const mortgageLayer = new FeatureLayer({
  portalItem: {
    id: ParcelLayerId,
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: mortgageRenderer,
  blendMode: "normal",
});

export const uccLayer = new FeatureLayer({
  portalItem: {
    id: ParcelLayerId,
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: uccRenderer,
  blendMode: "normal",
});

export const mortgageReleaseLayer = new FeatureLayer({
  portalItem: {
    id: ParcelLayerId,
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: mortgageReleaseRenderer,
  blendMode: "normal",
});

export const FinanceGroupLayer = new GroupLayer({ title: "FinanceGroupLayer" });
FinanceGroupLayer.visibilityMode = "independent";
FinanceGroupLayer.add(mortgageReleaseLayer);
FinanceGroupLayer.add(mortgageLayer);
FinanceGroupLayer.add(uccLayer);

export const NavigationGroupLayer = new GroupLayer({
  title: "NavigationGroupLayer",
});
NavigationGroupLayer.visibilityMode = "independent";
NavigationGroupLayer.add(Parcellightboxlayer);
NavigationGroupLayer.add(Civil_Divisions);
NavigationGroupLayer.add(PLSSFirstDivision);
NavigationGroupLayer.add(Counties);

const dashedBlueOutline = new SimpleLineSymbol({
  color: [0, 0, 255],
  width: 2,
  style: "dash",
});

const fillSymbol = new SimpleFillSymbol({
  color: [0, 0, 0, 0],
  outline: dashedBlueOutline,
});

const wetlandsRenderer = new SimpleRenderer({
  symbol: fillSymbol,
});

const WetlandsLayer = new FeatureLayer({
  id: "WetlandsLayer",
  renderer: wetlandsRenderer,
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer",
  popupEnabled: false,
  visible: false,
});

const FloodwayLayer = new FeatureLayer({
  id: "FloodwayLayer",
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer",
  outFields: ["esri_symbology"],
  definitionExpression:
    "esri_symbology = 'Special Floodway' OR esri_symbology = 'Regulatory Floodway'",
  popupEnabled: false,
  visible: false,
});

const FutureLayer = new FeatureLayer({
  id: "FutureLayer",
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer",
  outFields: ["esri_symbology"],
  definitionExpression:
    "esri_symbology = 'Future Conditions 1% Annual Chance Flood Hazard'",
  popupEnabled: false,
  visible: false,
});

const ReducedRiskLayer = new FeatureLayer({
  id: "ReducedRiskLayer",
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer",
  outFields: ["esri_symbology"],
  definitionExpression:
    "esri_symbology = 'Area with Reduced Risk Due to Levee'",
  popupEnabled: false,
  visible: false,
});

const OneAnnualLayer = new FeatureLayer({
  id: "OneAnnualLayer",
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer",
  outFields: ["esri_symbology"],
  definitionExpression: "esri_symbology = '1% Annual Chance Flood Hazard'",
  popupEnabled: false,
  visible: false,
});

const PTTwoLayer = new FeatureLayer({
  id: "PTTwoLayer",
  minScale: baseImageryScale,
  url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer",
  outFields: ["esri_symbology"],
  definitionExpression: "esri_symbology = '0.2% Annual Chance Flood Hazard'",
  popupEnabled: false,
  visible: false,
});

export const WaterGroupLayer = new GroupLayer({ title: "WaterGroupLayer" });
WaterGroupLayer.visibilityMode = "independent";
WaterGroupLayer.add(WetlandsLayer);
WaterGroupLayer.add(FloodwayLayer);
WaterGroupLayer.add(FutureLayer);
WaterGroupLayer.add(ReducedRiskLayer);
WaterGroupLayer.add(OneAnnualLayer);
WaterGroupLayer.add(PTTwoLayer);

const CDL2023Layer = new ImageryLayer({
  id: "CDL2023Layer",
  portalItem: {
    id: "6d9c03213d874def89663afc26189acf",
  },
  definitionExpression: "name = 'USA_Cropland_2023'",
  visible: false,
  opacity: 0.5,
  popupEnabled: false,
});

export const CDLGroupLayer = new GroupLayer({ title: "CDLGroupLayer" });
CDLGroupLayer.visibilityMode = "independent";
CDLGroupLayer.add(CDL2023Layer);

export const CityGroupLayer = new GroupLayer({ title: "CityGroupLayer" });
CityGroupLayer.visibilityMode = "independent";
CityGroupLayer.add(CityDonutLayer);

const WaterWellsKansasLayer = new FeatureLayer({
  id: "WaterWellsKansasLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "921cf865410e4530becbd5aa6079bad1",
  },
  visible: false,
});
const WaterWellsMissouriLayer = new FeatureLayer({
  id: "WaterWellsMissouriLayer",
  url: "https://gis.dnr.mo.gov/host/rest/services/geology/logged_wells/MapServer&source=sd",
  minScale: baseImageryScale,
  popupEnabled: true,

  visible: false,
});
export const WaterWellsGroupLayer = new GroupLayer({
  title: "WaterWellsGroupLayer",
});
WaterWellsGroupLayer.add(WaterWellsKansasLayer);
WaterWellsGroupLayer.add(WaterWellsMissouriLayer);

const SoilsLayer = new FeatureLayer({
  id: "SoilsLayer",
  minScale: baseImageryScale,
  popupEnabled: false,
  opacity: 0.4,
  portalItem: {
    id: "06e5fd61bdb6453fb16534c676e1c9b9",
  },
  visible: false,
});

export const SoilHighlightLayer = new FeatureLayer({
  id: "SoilsLayer",
  minScale: baseImageryScale,
  popupEnabled: false,
  opacity: 0.2,
  portalItem: {
    id: "06e5fd61bdb6453fb16534c676e1c9b9",
  },
  visible: false,
});

export const SoilsGroupLayer = new GroupLayer({ title: "SoilsGroupLayer" });
SoilsGroupLayer.visibilityMode = "independent";
SoilsGroupLayer.add(SoilsLayer);
SoilsGroupLayer.add(SoilHighlightLayer);

export const AircraftLayer = new FeatureLayer({
  id: "AircraftLayer",
  portalItem: {
    id: "54ec1feb2577403d9d1ef8dc7a3342a7",
  },
  outFields: ["OBJECTID", "state", "county", "address"],
  visible: false,
  popupEnabled: false,
});

export const AircraftGroupLayer = new GroupLayer({
  title: "AircraftGroupLayer",
});
AircraftGroupLayer.visibilityMode = "independent";
AircraftGroupLayer.add(AircraftLayer);
