import axiosBalancer from "../api";
import { IDashboardFilters } from "../types/financialOverview";
import { IInterestRateResponse } from "../types/mortgage";
import { IFetchResponse } from "../types/table";

export const getLoanInstitutions = async (filters: IDashboardFilters): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/loan-institutions",
      method: "post",
      data: filters,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getMonthlyMortgages = async (params: IDashboardFilters, signal: AbortSignal): Promise<any[] | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/monthly-mortgages",
      method: "post",
      data: params,
      signal: signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalMortgages = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-mortgages",
      method: "post",
      data: params,
      signal: signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getUccFiled = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-ucc",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalLoan = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-loan-amount",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getEstimatedInterest = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/estimated-interest",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalAcreCount = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-acre-count",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getFarmableAcres = async (params: IDashboardFilters, signal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-farmable-acres",
      method: "post",
      data: params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getInterestRates = async (params: { start_date: string; end_date: string }): Promise<IFetchResponse<IInterestRateResponse>> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/interest-rates",
      method: "get",
      params: { ...params },
    });
    return { data: response.data, total_count: response.data.length } as IFetchResponse<IInterestRateResponse>;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error message:", error.message);
    } else {
      console.error("Unexpected error", error);
    }
    return { data: [], total_count: 0 } as IFetchResponse<IInterestRateResponse>;
  }
};
