import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { getOwnerAddressSuggestions } from "../services/suggestions";
import { OwnerAddressSuggestionsRequest } from "../types/suggestions";
import usePrevious from "./usePrevious";

interface UseSuggestionsParams {
  state: string;
  county?: string[];
  field: OwnerAddressSuggestionsRequest["field"];
  partial: string;
}

export const useOwnerAddressSuggestions = ({
  state,
  county = [],
  field,
  partial,
}: UseSuggestionsParams) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const prevState = usePrevious(state);
  const prevCounty = usePrevious(county);
  const prevPartial = usePrevious(partial);

  const fetchSuggestions = async () => {
    setIsLoading(true);
    setError(null);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      const response = await getOwnerAddressSuggestions(
        { state, county, field, partial },
        controller.signal
      );
      if (response) setSuggestions(response.suggestions);
    } catch (err) {
      if (!controller.signal.aborted) {
        setError("Error fetching suggestions");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!state || !partial.trim()) {
      //("Skipping fetch: Missing state or partial value.");
      return;
    }

    const isStateChanged = state !== prevState;
    const isCountyChanged = !_.isEqual(prevCounty, county);
    const isPartialChanged = partial !== prevPartial;

    if (!isStateChanged && !isCountyChanged && !isPartialChanged) {
      //"Skipping fetch: No relevant parameters changed.");
      return;
    }

    fetchSuggestions();
  }, [state, county, field, partial]);

  return { suggestions, isLoading, error };
};
