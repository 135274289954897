import Graphic from "@arcgis/core/Graphic.js";
import { ComponentType } from "../utils/mortgage";
import { IFetchParams } from "./table";

export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export interface ISearchFormValues {
  //region
  [SearchRegionFields.State]: IOption;
  [SearchRegionFields.County]: IOption[];
  //owner
  [SearchOwnerFields.OWNER_1_FIRST]: IOption[];
  [SearchOwnerFields.OWNER_1_LAST]: IOption[];
  [SearchOwnerFields.OWNER_NAME_1]: IOption[];
  //address
  [SearchAddressFields.SiteAddr]: IOption[];
  [SearchAddressFields.SiteCity]: IOption[];
  [SearchAddressFields.SiteZIP]: IOption[];
  //advanced
  [SearchFieldIdName.Parcel]: IOption[];
  [SearchFieldIdName.UniqueTax]: IOption[];
  [SearchFieldIdName.AlternateTax]: IOption[];
  [SearchFieldIdName.ParcelLid]: IOption[];
  [SearchAdvancedFields.Township]: IOption[];
  [SearchAdvancedFields.Range]: IOption[];
  [SearchAdvancedFields.Section]: IOption[];
  [SearchAdvancedFields.Block]: IOption[];
  [SearchAdvancedFields.Lot]: IOption[];
  [SearchFilterTable.FilterValue1]: IOption[];
  [SearchFilterTable.FilterValue2]: IOption[];
}

export enum OwnerSearchType {
  OWNER_NAME = "owner_name",
  BUSINESS_NAME = "business_name",
}

export enum SearchFieldIdName {
  Parcel = "parcel_apn",
  UniqueTax = "uniqueTax",
  AlternateTax = "alternateTax",
  ParcelLid = "parcel_lid",
}

export enum SearchRegionFields {
  State = "state",
  County = "county",
}

export enum SearchOwnerFields {
  OWNER_1_FIRST = "OWNER_1_FIRST",
  OWNER_1_LAST = "OWNER_1_LAST",
  OWNER_NAME_1 = "OWNER_NAME_1",
}

export enum SearchAddressFields {
  SiteAddr = "site_Addr",
  SiteCity = "site_city",
  SiteZIP = "site_zip",
}

export enum SearchAdvancedFields {
  Township = "township",
  Range = "range",
  Section = "section",
  Block = "block_number",
  Lot = "lot_number",
}

export enum SearchFilterTable {
  FilterValue1 = "FilterValue1",
  FilterValue2 = "FilterValue2",
}

export interface ISearchFields {
  label: string;
  name:
    | SearchFieldIdName
    | SearchRegionFields
    | SearchOwnerFields
    | SearchAddressFields
    | SearchAdvancedFields
    | SearchFilterTable
    | any;
  placeholder?: string;
  options?: IOption[];
  isMulti: boolean;
  prevOption?:
    | SearchFieldIdName
    | SearchRegionFields
    | SearchOwnerFields
    | SearchAddressFields
    | SearchAdvancedFields;
  queryFieldName?: string;
  defaultValue?: IOption;
  searchSubstrings?: boolean;
  required?: boolean;
  component?: ComponentType;
  rules?: any;
  allowCreateOption?: boolean;
}

export interface IMapSearchFetchParams extends IFetchParams {
  parcel_lid?: string[];
  parcel_apn?: string[];
  unique_taxapn?: string[];
  alternate_taxapn?: string[];
  OWNER_1_FIRST?: string[];
  OWNER_1_LAST?: string[];
  OWNER_NAME_1?: string[];
  SITE_ADDR?: string[];
  SITE_CITY?: string[];
  SITE_ZIP?: string[];
  county?: string[];
  section?: string[];
  township?: string[];
  range?: string[];
  block_number?: string[];
  lot_number?: string[];
}

export interface IMapSearchResult {
  parcel_lid: string;
  site_state: string;
  owner_name: string;
  description: string;
  county: string;
  section: string;
  township: string;
  range: string;
  block_number: string;
  lot_number: string;
  mortgage?: boolean;
  ucc?: string;
}

interface Parcel {
  parcelFeature: Graphic;
}

export type ParcelResult = IMapSearchResult & Parcel;

export enum SearchOwnerIdName {
  OwnerName = "owner_name",
  BusinessName = "business_name",
}
