import React from "react";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import { IOption, ISearchFormValues, SearchRegionFields } from "../../../types/search";
import { ComponentType } from "../../../utils/mortgage";
import Input from "../../atoms/Input";
import { SelectComponent } from "../../atoms/Select";
import DatePicker from "../../molecules/DatePicker";
import SearchSelect from "../../molecules/Sidebar/Search/SearchSelect";
import MultiFilterSelect from "../MultiFilterSelect";

interface IDynamicInputProps {
  name: string;
  options?: IOption[];
  label: string;
  control?: Control<any>;
  component: ComponentType;
  errorMessage?: string;
  isMulti?: boolean;
  register: UseFormRegister<any>;
  readOnly?: boolean;
  rules?: any;
}

const DynamicInput = ({ name, options, control, component, register, rules, ...rest }: IDynamicInputProps) => {
  const renderInput = (component: ComponentType) => {
    switch (component) {
      case "Select":
        return <SelectComponent name={name} control={control as Control<FieldValues>} {...rest} options={options || []} />;
      case "Input":
        return <Input {...rest} {...register(name, rules)} />;
      case "DatePicker":
        return <DatePicker control={control as Control<FieldValues>} name={name} {...rest} />;
      case "SearchSelect":
        return (
          <SearchSelect
            searchInput={{
              ...rest,
              name: name as SearchRegionFields,
              isMulti: rest.isMulti as boolean,
            }}
            isSubmitting={false}
            control={control as Control<ISearchFormValues>}
          />
        );
      case "MultiFilterSelect":
        return <MultiFilterSelect control={control as Control<any, any, any>} name={name} {...rest} />;
      default:
        return null;
    }
  };

  return <>{renderInput(component)}</>;
};

export default DynamicInput;
