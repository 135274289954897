import React, { useState, useEffect } from "react";
import { Control, RegisterOptions, UseFormSetValue } from "react-hook-form";
import {
  ISearchFields,
  ISearchFormValues,
  IOption,
} from "../../../../types/search";
import { CreatableSelectComponent } from "../../../atoms/Select";
import CustomMultiValue from "../../../features/Dashboard/MultiValue/MultiValue";
import CheckboxOption from "../../../atoms/Select/CheckboxOption";
import MultiFilterSelect from "../../../features/MultiFilterSelect";
import { InputActionMeta } from "react-select";

type SelectType = "creatable" | "multi-filter";

interface ISearchSelectProps {
  control: Control<ISearchFormValues>;
  searchInput: ISearchFields;
  isSubmitting: boolean;
  title?: string;
  setValue?: UseFormSetValue<ISearchFormValues>;
  rules?:
    | Omit<
        RegisterOptions<any, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  selectType?: SelectType;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  isLoadingOptions?: boolean;
}

const SearchSelect = ({
  control,
  searchInput,
  isSubmitting,
  title,
  rules,
  setValue,
  selectType = "creatable",
  onInputChange,
  isLoadingOptions = false,
}: ISearchSelectProps) => {
  const [searchOptions, setSearchOptions] = useState<IOption[]>([]);

  // TODO: Implement this to use our backend API
  // const INPUT_OPTIONS_RESPONSE_COUNT = 50;
  // const getSearchInputOptions = async (input: string, clearExistingOptions?: boolean) => {
  //   try {
  //     setIsLoading(true);

  //     setSearchOptions([]);
  //   } catch (error) {
  //     console.error('Error fetching search input options:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onCreateOption = (inputValue: string) => {
    if (!inputValue.trim()) return;

    const newOption = { value: inputValue, label: inputValue };

    setSearchOptions((prevOptions) => {
      const isOptionExist = prevOptions.some(
        (option) => option.value === inputValue
      );
      return isOptionExist ? prevOptions : [...prevOptions, newOption];
    });

    setTimeout(() => {
      const currentValue = control._getWatch(searchInput.name) as IOption[];
      const updatedValue = Array.isArray(currentValue)
        ? [...currentValue, newOption]
        : [newOption];
      setValue &&
        setValue(searchInput.name, updatedValue, { shouldValidate: true });
    }, 0);
  };

  useEffect(() => {
    setSearchOptions([]);
  }, [searchInput.name]);

  const SelectComponent =
    selectType === "creatable" ? CreatableSelectComponent : MultiFilterSelect;

  return (
    <>
      <div>
        {title && <h3 className="text-2xl mb-4">{title}</h3>}
        <SelectComponent
          control={control}
          name={searchInput.name}
          label={searchInput.label}
          placeholder={searchInput.placeholder}
          isMulti={searchInput.isMulti}
          closeMenuOnSelect={!searchInput.isMulti}
          options={searchInput.options || searchOptions}
          defaultValue={searchInput.defaultValue}
          createOptionPosition="first"
          isSearch={isLoadingOptions}
          isSearching={isLoadingOptions}
          isDisabled={isSubmitting}
          onInputChange={onInputChange}
          isLoading={isLoadingOptions}
          rules={rules}
          allowCreateOption={searchInput.allowCreateOption}
          onCreateOption={(inputValue: string) => onCreateOption(inputValue)}
          components={{
            Option: (optionProps) =>
              optionProps.data.value && optionProps.data.label ? (
                <CheckboxOption
                  {...optionProps}
                  isMulti={searchInput.isMulti || false}
                />
              ) : null,
            MultiValue: (props) =>
              searchInput.isMulti ? <CustomMultiValue {...props} /> : null,
          }}
        />
      </div>
    </>
  );
};

export default SearchSelect;
