import numeral from "numeral";

export const formatMoneyFull = (amount?: number): string => {
  if (amount == null) return "0.00";
  return numeral(amount).format("$0,0.00");
};

export const formatMoney = (amount?: number): string => {
  if (amount == null) return "0.00";
  return numeral(amount).format("($ 0.[00] a)").toUpperCase(); // Uppercase for abbreviations
};

export const parseMoney = (formattedAmount: string): number => {
  if (!formattedAmount) return 0;
  const cleanAmount = formattedAmount.replace(/[$()]/g, "").trim();
  const parsedNumber = numeral(cleanAmount).value();

  return parsedNumber ?? 0;
};

export const formatInterestRate = (rate: string): string => {
  const parsedRate = parseFloat(rate);
  const sign = parsedRate >= 0 ? "" : "-";
  const formattedRate = sign + Math.abs(parsedRate).toFixed(2) + "%";

  return formattedRate;
};

export const capitalizeEachWord = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
