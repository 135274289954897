import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../redux/actions";
import { IMortgageHolder } from "../../../types/financialOverview";
import InfiniteScrollTable from "../../molecules/InfiniteScrollTable";
import { ColumnDef } from "@tanstack/react-table";
import { formatDate } from "../../../utils/date";
import { formatMoney } from "../../../utils/string";
import {
  EmptyFetchResponse,
  getDefaultFetchParams,
  IFetchResponse,
} from "../../../types/table";
import { IMortgageHoldersFetchParams } from "../../../types/mortgage";
import { getMortgageHolders } from "../../../services/mortgage";

const CityPopup = ({ city }: { city: string }) => {
  const dispatch = useDispatch();
  const fetchParams = useMemo(
    () => ({
      ...getDefaultFetchParams(),
      city,
    }),
    [city]
  );

  const loadMortgageHolders = async (
    params: IMortgageHoldersFetchParams,
    signal: AbortSignal
  ): Promise<IFetchResponse<IMortgageHolder>> => {
    try {
      return await getMortgageHolders(params, signal);
    } catch (error: unknown) {
      if (error instanceof Error && error?.name != "AbortError") {
        console.error("Error fetching UCC data", error);
      }
    }
    return EmptyFetchResponse;
  };

  const onRowClick = (row: IMortgageHolder) => {
    dispatch(setSelectedParcelLId(row.parcel_lid));
  };

  const columns = useMemo<ColumnDef<IMortgageHolder>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return <>{formatDate(value)}</>;
        },
      },
      {
        accessorKey: "loan_institution",
        header: "From",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (info) => info.renderValue(),
      },
      {
        accessorKey: "borrower",
        header: "To",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "county",
        header: "County",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "city",
        header: "City",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        accessorKey: "mortgage_amount",
        header: "Mortgage",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue();
          return <>{formatMoney(value as number)}</>;
        },
      },
    ],
    []
  );

  return (
    <InfiniteScrollTable
      className="max-h-[600px] max-w-[1000px]"
      columns={columns}
      fetchData={loadMortgageHolders}
      fetchParams={fetchParams}
      onRowClick={onRowClick}
    />
  );
};

export default CityPopup;
