import { isAxiosError } from "axios";
import axios from "../api";
import {
  IAuthStatus,
  IMFAResponse,
  IMFASetupResponse,
  IMFAStatus,
} from "../types/auth.types";

export const checkAuthStatus = async (): Promise<IAuthStatus | null> => {
  try {
    const response = await axios.get("/api/auth/check");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const login = async (
  username: string,
  password: string
): Promise<IMFAStatus | null> => {
  try {
    const response = await axios.post("/api/auth/login", {
      username,
      password,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        return null;
      }
    }
    throw new Error("Unexpected error during login");
  }
};

export const logout = async () => {
  try {
    const response = await axios.post("/api/auth/logout");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const checkPasswordResetToken = async (email: string, token: string) => {
  try {
    const response = await axios.get(
      `/api/auth/check-password-token?email=${encodeURIComponent(
        email
      )}&token=${token}`
    );
    return response.status == 200;
  } catch (error) {
    return false;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response = await axios.post("/api/auth/forgot-password", {
      email: email,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const resetPassword = async (
  email: string,
  token: string,
  password: string
) => {
  try {
    const response = await axios.post("/api/auth/reset-password", {
      email: email,
      token: token,
      password: password,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const initializeMFASetup =
  async (): Promise<IMFASetupResponse | null> => {
    try {
      const response = await axios.post<IMFASetupResponse>(
        "/api/auth/mfa-enable-init"
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (error: unknown) {
      console.error("Error initializing MFA setup:", error);
      return null;
    }
  };

export const confirmMFASetup = async (
  totpCode: string
): Promise<IMFAResponse | null> => {
  try {
    const response = await axios.post<IMFAResponse>(
      "/api/auth/mfa-enable-confirm",
      { totp_code: totpCode }
    );

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error: unknown) {
    console.error("Error confirming MFA setup:", error);
    return null;
  }
};

export const verifyMFA = async (
  totpCode: string
): Promise<IAuthStatus | null> => {
  try {
    const response = await axios.post<IAuthStatus>("/api/auth/mfa-verify", {
      totp_code: totpCode,
    });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error: unknown) {
    console.error("Error verifying MFA code:", error);
    return null;
  }
};

export const requestMFAReset = async (): Promise<IMFAResponse | null> => {
  try {
    const response = await axios.post<IMFAResponse>(
      "/api/auth/mfa-reset-request"
    );
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error: unknown) {
    console.error("Error requesting MFA reset:", error);
    return null;
  }
};

export const resetMFA = async (token: string): Promise<IMFAResponse | null> => {
  try {
    const response = await axios.post<IMFAResponse>("/api/auth/mfa-reset", {
      token: token,
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error: unknown) {
    console.error("Error resetting MFA:", error);
    return null;
  }
};
