import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, ReactNode } from "react";

interface CollapsibleProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
  isOpen = false,
}) => {
  const [open, setOpen] = useState(isOpen);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <div className="w-full">
      <button
        className="flex justify-between w-full p-2 font-bold"
        type="button"
        onClick={toggle}
      >
        {title}
        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
      </button>
      {open && <div className="flex flex-col gap-6">{children}</div>}
    </div>
  );
};

export default Collapsible;
