import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import {
  getEstimatedInterest,
  getFarmableAcres,
  getMonthlyMortgages,
  getTotalAcreCount,
  getTotalLoan,
  getTotalMortgages,
  getUccFiled,
} from "../../../../services/dashboard";
import { getDateOnly } from "../../../../utils/financialOverview";
import { formatMoney, formatMoneyFull } from "../../../../utils/string";
import BarChart from "../../../atoms/charts/BarChart";
import ChartCollection from "../../../atoms/charts/ChartCollection";
import LineChart from "../../../atoms/charts/LineChart";
import PieChart from "../../../atoms/charts/PieChart";
import CalendarDayIcon from "../../../icons/CalendarDay.icon";
import BarChartIcon from "../../../icons/Chart.icon";
import LineChartIcon from "../../../icons/ChartLine.icon";
import CheckMoneyIcon from "../../../icons/CheckMoney.icon";
import HandsHelpingIcon from "../../../icons/HandsHelping.icon";
import LandMarkIcon from "../../../icons/LandMark.icon";
import MoneyBillWaveIcon from "../../../icons/MoneyBillWave.icon";
import PieChartIcon from "../../../icons/PieChart.icon";
import DashboardCard from "../DashboardCard";
import MortgageHolder from "../MortgageHolder";
import { IDashboardFilters } from "../../../../types/financialOverview";

interface IFinancialOverviewProps {
  state: string;
  time_period: string;
  start_date: Date | null;
  end_date: Date | null;
  loan_institutions: string[];
  counties: string[];
}

const FinancialOverview = ({
  state,
  time_period,
  start_date,
  end_date,
  loan_institutions,
  counties,
}: IFinancialOverviewProps) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [mortgageCount, setMortgageCount] = useState(-1);
  const [uccCount, setUccCount] = useState(-1);
  const [isLoadingLineChart, setIsLoadingLineChart] = useState(true);
  const [chartMessage, setChartMessage] = useState("");
  const [lineChartData, setLineChartData] = useState<{
    values: number[];
    labels: string[];
  } | null>();

  const cardParams = useMemo(() => {
    return {
      state,
      start_date: getDateOnly(start_date ?? new Date()),
      end_date: getDateOnly(end_date ?? new Date()),
      loan_institutions,
      counties,
    } as IDashboardFilters;
  }, [state, start_date, end_date, loan_institutions, counties]);

  const leftCards = useMemo(
    () => [
      {
        title: "Total Mortgages",
        icon: HandsHelpingIcon,
        fetchFunction: getTotalMortgages,
        valueField: "total_mortgages",
        valueFormat: (val: number) => val.toLocaleString(),
        callback: (val: number) => setMortgageCount(val),
      },
      {
        title: "UCC Filed",
        icon: CheckMoneyIcon,
        fetchFunction: getUccFiled,
        valueField: "ucc_filed_count",
        valueFormat: (val: number) => val.toLocaleString(),
        callback: (val: number) => setUccCount(val),
        tooltipcontent: () => "Data only exists in KS.",
      },
      {
        title: "Total Loan",
        icon: MoneyBillWaveIcon,
        fetchFunction: getTotalLoan,
        valueField: "total_loan_amount",
        valueFormat: (val: number) => formatMoney(val),
        tooltipcontent: (val: number) => formatMoneyFull(val),
      },
      {
        title: "Estimated Interest",
        icon: MoneyBillWaveIcon,
        fetchFunction: getEstimatedInterest,
        valueField: "total_interest_amount",
        valueFormat: (val: number) => formatMoney(val),
        tooltipcontent: (val: number) => formatMoneyFull(val),
      },
    ],
    []
  );
  const rightCards = useMemo(
    () => [
      {
        title: "Total Acre Count",
        icon: LandMarkIcon,
        fetchFunction: getTotalAcreCount,
        valueField: "total_acre_count",
        valueFormat: (val: number) => val.toLocaleString(),
      },
      {
        title: "Farmable Acres",
        icon: CalendarDayIcon,
        fetchFunction: getFarmableAcres,
        valueField: "farmable_acres_count",
        valueFormat: (val: number) => val.toLocaleString(),
      },
    ],
    []
  );

  const getLineChartData = async (): Promise<any> => {
    if (start_date && end_date) {
      const today = new Date();
      const currentMonthEnd = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ); // Last day of the current month

      // Adjust endDate to not go beyond the current month
      const adjustedEndDate =
        end_date > currentMonthEnd ? currentMonthEnd : end_date;

      // If < 93 days, clear chart
      if (
        adjustedEndDate.getTime() - start_date.getTime() <
        93 * 24 * 60 * 60 * 1000
      ) {
        setLineChartData(null);
        setChartMessage("Please expand date range to get comprehensive data");
        setIsLoadingLineChart(false);
      } else {
        try {
          const response = await getMonthlyMortgages(
            {
              ...cardParams,
              end_date: getDateOnly(adjustedEndDate),
            },
            signal
          );
          if (response && response.length > 0) {
            const values = response.map((item) => item.total_mortgages);
            const labels = response.map((item) => {
              const [year, month] = item.month_year.split("-");
              return new Date(
                parseInt(year),
                parseInt(month) - 1
              ).toLocaleString("default", {
                month: "long",
                year: "numeric",
              });
            });
            return { values, labels };
          } else {
            return null;
          }
        } catch (error) {
          if (!signal.aborted) {
            return null;
          }
        } finally {
          if (!signal.aborted) {
            setIsLoadingLineChart(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    setIsLoadingLineChart(true);
    setMortgageCount(-1);
    setUccCount(-1);

    if (state && start_date && end_date) {
      getLineChartData().then((result) => {
        if (!signal.aborted) {
          if (result?.values?.length > 0) {
            setLineChartData(result);
          } else {
            setLineChartData(null);
            setChartMessage("No data available for these filters");
          }
          setIsLoadingLineChart(false);
        }
      });
    } else {
      setLineChartData({ values: [], labels: [] });
      setMortgageCount(0);
      setUccCount(0);
      setIsLoadingLineChart(false);
    }

    // Cleanup any pending requests
    return () => {
      abortController.abort();
    };
  }, [state, start_date, end_date, loan_institutions, counties]);

  return (
    <div className={clsx("p-8")}>
      <div className="flex w-full justify-between">
        <div className="flex w-[48%] h-full flex-wrap gap-6 justify-between">
          {leftCards.map((cardData, index) => (
            <div className="w-[46%] xl:w-[47%]" key={`card_${index}`}>
              <DashboardCard
                title={cardData.title}
                icon={cardData.icon}
                fetchFunction={cardData.fetchFunction}
                fetchParams={cardParams}
                valueField={cardData.valueField}
                percentField="change_percentage"
                timePeriod={time_period?.toLowerCase()}
                valueFormat={cardData.valueFormat}
                callback={cardData.callback}
                tooltipcontent={cardData.tooltipcontent}
              />
            </div>
          ))}
          <ChartCollection
            chartList={[
              {
                isLoading: mortgageCount < 0 || uccCount < 0,
                isEmpty: false,
                emptyMessage: "No data available for these filters",
                icon: (
                  <BarChartIcon width={24} height={24} cursor={"pointer"} />
                ),
                chart: (
                  <BarChart
                    labels={["Mortgages", "UCCs", "Both"]}
                    values={[mortgageCount, uccCount, mortgageCount + uccCount]}
                  />
                ),
              },
              {
                isLoading: isLoadingLineChart,
                isEmpty: !lineChartData || lineChartData.values.length < 1,
                emptyMessage: chartMessage,
                icon: (
                  <LineChartIcon width={24} height={24} cursor={"pointer"} />
                ),
                chart: (
                  <div className="h-full">
                    <div className="text-center w-full">
                      Mortgages Created per Month
                    </div>
                    <div className="h-full">
                      <LineChart
                        labels={lineChartData?.labels}
                        values={lineChartData?.values || []}
                      />
                    </div>
                  </div>
                ),
              },
              {
                isLoading: mortgageCount < 0 || uccCount < 0,
                isEmpty: false,
                emptyMessage: "No data available for these filters",
                icon: <PieChartIcon cursor={"pointer"} />,
                chart: (
                  <PieChart
                    labels={["Mortgages", "UCCs", "Both"]}
                    values={[mortgageCount, uccCount, mortgageCount + uccCount]}
                    colors={["#0263ff", "#f97223", "#8e30ff"]}
                  />
                ),
              },
            ]}
          />
        </div>

        <div className="flex w-[48%] h-full flex-wrap gap-6 justify-between">
          {rightCards.map((cardData, index) => (
            <div className="w-[46%] xl:w-[47%]" key={`card_${index}`}>
              <DashboardCard
                title={cardData.title}
                icon={cardData.icon}
                fetchFunction={cardData.fetchFunction}
                fetchParams={cardParams}
                valueField={cardData.valueField}
                percentField="change_percentage"
                timePeriod={time_period?.toLowerCase()}
                valueFormat={cardData.valueFormat}
              />
            </div>
          ))}

          <div className="w-full">
            <MortgageHolder state={state as string} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverview;
