import React, { useState } from "react";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "./login.schema";
import { InferType } from "yup";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ToastOptions, toast } from "react-toastify";
import { ToastSettings } from "../../utils/toast";
import unsplash from "../../assets/images/unsplash.jpg";
import logo from "../../assets/images/Logo.png";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (values: InferType<typeof loginSchema>) => {
    setLoading(true);
    const { username, password } = values;

    try {
      const data = await login(username, password);
      setLoading(false);
      if (!data) {
        return;
      }
      navigate("/mfa-setup");
    } catch (error) {
      setLoading(false);
      toast.error(
        "An unexpected error occurred.",
        ToastSettings as ToastOptions
      );
    }
  };

  return (
    <div
      className="bg-boundsPurple-150 h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${unsplash})` }}
    >
      <div className="absolute inset-0 bg-boundsPurple-500 bg-opacity-75">
        <section className="w-full h-full flex justify-center items-center">
          <form
            className="border border-boundsYellow-50 bg-boundsPurple-700 pt-5 pb-5 px-8 rounded-2xl shadow-xl flex flex-col gap-4 min-w-[310px] lg:w-[390px] lg:pt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <img className="mx-auto h-113 w-257" src={logo}></img>
            <div>
              <Input label={"Username"} type="text" {...register("username")} />
              {errors.username && (
                <span className="font-bold text-red-500 text-[12px]">
                  {errors.username.message}
                </span>
              )}
            </div>
            <div>
              <Input
                label={"Password"}
                type="password"
                {...register("password")}
              />
              {errors.password && (
                <span className="font-bold text-red-500 text-[12px]">
                  {errors.password.message}
                </span>
              )}
            </div>
            <Button
              className="w-full mt-2"
              label={"Continue"}
              type="submit"
              isLoading={loading}
            />
            <button
              className="text-amber-100 text-[12px] block text-center"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Login;
