import clsx from "clsx";
import React from "react";

interface IInput {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  error?: boolean;
  readOnly?: boolean;
  className?: string;
  variant?: "primary" | "secondary" | "filled";
  size?: "sm" | "md" | "lg";
}

const Input = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      label,
      placeholder,
      type = "text",
      name,
      errorMessage,
      error = false,
      readOnly = false,
      className,
      variant = "primary",
      size = "md",
      ...props
    },
    ref
  ) => {
    const containerBaseStyles =
      "relative flex flex-col justify-between rounded-t-md py-3 px-2 border-b h-full";

    const inputBaseStyles =
      "focus:outline-none w-full bg-transparent placeholder-boundsPurple-100 font-sm";

    const variantStyles = {
      primary: {
        container: "bg-boundsGray-100 border-white text-boundsPurple-400",
        input: "text-boundsPurple-400",
      },
      secondary: {
        container:
          "bg-boundsPurple-150 border-boundsYellow-50 text-boundsGray-100",
        input: "text-boundsGray-100 !placeholder-boundsGray-100",
      },
      filled: {
        container: "bg-boundsPurple-100 text-white px-3 py-2 rounded-md",
        input: "text-white",
      },
    };

    const sizeStyles = {
      sm: "text-xs py-1 px-2",
      md: "text-sm py-2 px-3",
      lg: "text-base py-3 px-4",
    };

    return (
      <div className={clsx("w-full", className)}>
        <div
          className={clsx(
            containerBaseStyles,
            variantStyles[variant].container,
            sizeStyles[size],
            error && "border-red-500"
          )}
        >
          <label className="text-xs block mb-1 text-inherit">{label}</label>
          <input
            ref={ref}
            name={name}
            className={clsx(
              inputBaseStyles,
              variantStyles[variant].input,
              readOnly && "cursor-default"
            )}
            placeholder={placeholder}
            type={type}
            readOnly={readOnly}
            {...props}
          />
        </div>

        {errorMessage && (
          <span className="text-[12px] text-red-500 mt-1">{errorMessage}</span>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
